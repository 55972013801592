import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponentComponent } from './components/login-component/login-component.component';
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { content } from "./shared/routes/routes";
import { AuthGuardGuard } from "./auth-guard.guard"
import { AdminPanelComponent } from './components/admin-panel/admin-panel.component';
import { MultisigjourneyComponent } from './components/multisigjourney/multisigjourney.component';
import { GetStartedComponent } from './components/get-started/get-started.component';
import { SignupComponent } from './components/signup/signup.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { ErrorPageComponent } from './shared/components/error-page/error-page.component';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { ChooseOrganizationComponent } from './components/choose-organization/choose-organization.component';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminPanelComponent,
    pathMatch: 'full',
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'multisigsetup',
    component: MultisigjourneyComponent,
    pathMatch: 'full',
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'login',
    component: LoginComponentComponent,
    pathMatch: 'full'
  },
  {
    path: 'access-denied',
    component: ErrorPageComponent,
    pathMatch:'full'
  },
  {
    path: 'restricted-access',
    component: AccessDeniedComponent,
    pathMatch: 'full'
  },
  {
    path: 'join',
    component: SignupComponent,
    pathMatch: 'full'
  },
  {
    path: 'get-started',
    component: GetStartedComponent,
    pathMatch: 'full',
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'verify-email',
    component: VerifyEmailComponent,
    pathMatch: 'full',
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'choose-organization',
    component: ChooseOrganizationComponent,
    pathMatch: 'full'
  },
  {
    path: '',
    component: ContentComponent,
    children: content,
    canActivate: [AuthGuardGuard]
  },
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: ''
  },

];
@NgModule({
  imports: [[RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    // enableTracing: true
  })],
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

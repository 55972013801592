import {Injectable} from '@angular/core';
import {ErrorPrompt} from '../components/custom-prompt/custom-prompt.service';
import {SegmentService} from 'ngx-segment-analytics';

@Injectable()
export class ReportsService {

  constructor(private segment: SegmentService) {
  }
  
    filterParamObjs(filterDates:any[]) {
        let paramObj:any = null;
        if(filterDates.length) {
            filterDates.map(item => { paramObj = {...paramObj, ...{[item.month]:item.year}};});
        }
        return paramObj;
    }

    async handleMultipleReportsResponse(data, type, flag, filterDates, segmentData) {
        flag[0] = false;
        let defaultMsg = 
        type == 'compliance' ? "No Transaction Available for " : "Statement is not available for ";
        let errorMessage = defaultMsg;
        let showError = false;
        let segmentServiceTracked = false;
        let nopdfYears = [];

        for(let item of data?.data) {
          if(item.success) {
            await this.downloadFileWithDelay(item.data.url, 1000);
            if (!segmentServiceTracked) {
              this.segment.track(segmentData.event, segmentData).catch((_err) => { });
              segmentServiceTracked = true; // Set the flag to true after tracking
          }
          } else {
            if(item.code == 404 && item.message.toLowerCase().includes(defaultMsg.toLowerCase())) {
              nopdfYears.push(this.getMonthAndYear(item.message.substring(item.message.length - 9), filterDates));
              showError = true;
            }
          }
        }
        if(showError) {
          ErrorPrompt.fire({
            icon: "error",
            title: "Oops...",
            text: `${errorMessage} ${nopdfYears.join(", ")}`,
          });
        }
      }

      getMonthAndYear(val: string, filterDates) {
        const valArr = val.split('-');
        let m = valArr[0], y = valArr[1];
        return filterDates.filter(item => item.month == m && item.year == y)[0].date;
      }

      async downloadFile(url: string) {
        let link = document.createElement("a");
        link.setAttribute("type", "hidden");
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.remove();
      }

    //   Small delay ensures that previous network call is not cancelled due to new click event
    async downloadFileWithDelay(url: string, delay: number) {
        return new Promise((resolve) => {
            setTimeout(async () => {
                await this.downloadFile(url);
                resolve(true);
            }, delay);
        });
    }

      checkboxFilter(checked, data, filterDates ) {
        if(checked) {
            filterDates = [...filterDates, data];
          } else {
            filterDates = filterDates.filter(item => item.date != data.date);
          }
          return filterDates;
      }

      getCurrentDateMonth() {
        let currDate = new Date();
        let month = currDate.getMonth();
        let year = currDate.getFullYear();
        return { month, year };
      }

      checkCurrentDates(filterDates:any[]) {
        let currDate = this.getCurrentDateMonth();
          return filterDates.filter(item => item.month == currDate.month + 1 && item.year == currDate.year);
      }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateAgoPipe, DateWithZonePipe } from './date-ago.pipe';
import { GetFeesUnitPipe, GetIconPipe, GetNamePipe } from './coins';
import { AmountroundedPipe, toPrettyAmount } from './amountrounded.pipe';
import { SearchExchangePipe, SearchValidatorStakingPipe } from './search.pipe';
import { WalletBalanceSortPipe, ExchangeFilterPipe } from './policy-type-filter-pipe';
import { SearchPipe, SearchCoinPipe, SearchWalletPipe, SearchPolicyListPipe, SearchWalletListPipe, SearchRulesListPipe, SearchWalletAddressPipe, historySearchPipe, consolidationRuleSearchPipe, teamsListSearchPipe,
   fromToDatePipe, usersListSearchPipe, defaultImagePipe, pendingSearchPipe, refillSearchPipe, SearchWalletStakingPipe, SearchAssetsStakingPipe, protocol_search, PolicyFilterPipe } from './search.pipe';
import { PolicyTypeFilterPipe, WalletTypeFilterPipe, actionsFilterPipe, refillFilterPipe, UserTableSortPipe } from './policy-type-filter-pipe';

import { TeamTypeFilterPipe } from './team-type-filter-pipe';
import { paginationTOFrom } from './pagination_from_to';
import { NegtoposService } from './negtopos.service';
import { SafePipe } from './safe.pipe';
import { SplitStringPipe } from './title-case-after-comma.pipe';


@NgModule({
  declarations: [
    DateAgoPipe,
    GetNamePipe,
    GetIconPipe,
    GetFeesUnitPipe,
    AmountroundedPipe,
    SearchPipe,
    SearchCoinPipe,
    SearchWalletPipe,
    PolicyTypeFilterPipe,
    WalletBalanceSortPipe,
    SearchPolicyListPipe,
    SearchWalletListPipe,
    SearchRulesListPipe,
    SearchWalletAddressPipe,
    WalletTypeFilterPipe,
    actionsFilterPipe,
    historySearchPipe,
    consolidationRuleSearchPipe,
    teamsListSearchPipe,
    TeamTypeFilterPipe,
    fromToDatePipe,
    usersListSearchPipe,
    defaultImagePipe,
    refillFilterPipe,
    pendingSearchPipe,
    refillSearchPipe,
    SearchWalletStakingPipe,
    SearchAssetsStakingPipe,
    UserTableSortPipe,
    historySearchPipe,
    consolidationRuleSearchPipe,
    protocol_search,
    toPrettyAmount,
    paginationTOFrom,
    NegtoposService,
    ExchangeFilterPipe,
    SearchExchangePipe,
    DateWithZonePipe,
    SafePipe,
    ExchangeFilterPipe,
    SearchExchangePipe,
    PolicyFilterPipe,
    SearchValidatorStakingPipe,
    SplitStringPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DateAgoPipe,
    GetNamePipe,
    GetIconPipe,
    GetFeesUnitPipe,
    AmountroundedPipe,
    SearchPipe,
    SearchCoinPipe,
    SearchWalletPipe,
    PolicyTypeFilterPipe,
    WalletBalanceSortPipe,
    SearchPolicyListPipe,
    SearchWalletListPipe,
    SearchRulesListPipe,
    SearchWalletAddressPipe,
    WalletTypeFilterPipe,
    actionsFilterPipe,
    historySearchPipe,
    consolidationRuleSearchPipe,
    teamsListSearchPipe,
    TeamTypeFilterPipe,
    fromToDatePipe,
    usersListSearchPipe,
    defaultImagePipe,
    refillFilterPipe,
    pendingSearchPipe,
    refillSearchPipe,
    SearchWalletStakingPipe,
    SearchAssetsStakingPipe,
    UserTableSortPipe,
    historySearchPipe,
    consolidationRuleSearchPipe,
    protocol_search,
    toPrettyAmount,
    paginationTOFrom,
    NegtoposService,
    ExchangeFilterPipe,
    SearchExchangePipe,
    DateWithZonePipe,
    SafePipe,
    ExchangeFilterPipe,
    SearchExchangePipe,
    PolicyFilterPipe,
    SearchValidatorStakingPipe,
    SplitStringPipe
  ]
})
export class PipesModule { }

<div>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="rejectInvite(false)">
            <i class="icon-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12 confirmation-modal">
                <div class="img-logo">
                    <img src="../../../../assets/images/logo/logo.png" alt="logo.svg">
                </div>

                <div class="content text-center  mt-4">
                    <h4>Are you sure you want to reject the invite?</h4>
                    <p class="text-dark mt-3">This is an invite to join a new organisation, once rejected, you'll have
                        to ask the organisation to invite again.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer border-0">
        <button type="submit" class="btn btn-outline-primary w-100" (click)="rejectInvite(true)" >
            <span>Reject Invite</span>
        </button>
    </div>
</div>
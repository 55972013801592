import * as bitcoin from "bitcoinjs-lib";
import getConfig from "./getOverload";

export const environment = {
  version: "v0.43.08.27-n1" + "-predev",
  production: true,
  auth0ClientKey: getConfig('auth0ClientKey', '0R36qBgLJ2JjrENTAbtGYAx3j57vzBcr'),
  userGuidingId: "",
  auth0Domain: getConfig('auth0Domain', 'pre-dev-lmnl.us.auth0.com'),
  auth0SupportUrl: getConfig('auth0SupportUrl', 'mailto:support@lmnl.app'),
  disableTennetBuildFts: getConfig('disableTennetBuildFts', false),
  web3websocket: 'wss://rinkeby.infura.io/ws/v3/6d75665a708f4ec2a62d31acb7f48105',
  // apiUrl: 'http://localhost:3001/api',
  // apiUrl: 'https://pre-dev-api-gama.lmnl.dev/api',
  // apiUrl: 'https://pre-dev-api-iota.lmnl.dev/api',
  // apiUrl: 'https://api-theta.lmnl.dev/api',
  // apiUrl: 'https://api-delta.lmnl.dev/api',
  // apiUrl: 'https://pre-dev-api-zeta.lmnl.dev/api',
  // apiUrl: 'https://api-espilon.lmnl.dev/api',
  // apiUrl: 'https://pre-dev-api-kappa.lmnl.dev/api',
  apiUrl: 'https://pre-dev-api.lmnl.dev/api',
  //apiUrl: 'https://api-feature-env-1.lmnl.dev/api',
  // apiUrl: 'https://api-feature-env-2.lmnl.dev/api',
  // apiUrl: 'https://api-feature-env-3.lmnl.dev/api',
  // apiUrl: 'https://api-feature-env-4.lmnl.dev/api',
  // apiUrl: 'https://api-feature-env-5.lmnl.dev/api',
  // apiUrl: 'https://api-feature-env-6.lmnl.dev/api',
  // apiUrl: 'https://api-feature-env-7.lmnl.dev/api',
  // apiUrl: 'https://api-feature-env-8.lmnl.dev/api',
  // apiUrl: 'https://api-feature-env-9.lmnl.dev/api',
  // apiUrl: 'https://api-feature-env-10.lmnl.dev/api',
  // apiUrl: 'https://api-feature-env-11.lmnl.dev/api',
  // apiUrl: 'https://api-feature-env-12.lmnl.dev/api',
  // apiUrl: 'https://api-feature-env-13.lmnl.dev/api',
  // apiUrl: 'https://api-feature-env-14.lmnl.dev/api',
  // apiUrl: 'https://api-feature-env-15.lmnl.dev/api',
  // apiUrl: 'https://api-feature-env-16.lmnl.dev/api',
  // apiUrl: 'https://api-feature-env-17.lmnl.dev/api',
  // apiUrl: 'https://api-feature-env-18.lmnl.dev/api',
  // apiUrl: 'https://api-feature-env-19.lmnl.dev/api',
  // apiUrl: 'https://api-feature-env-20.lmnl.dev/api',
  
  // apiUrl: 'http://35.154.92.59:3001/api',
  adminPanelUrl: getConfig('apUrl', 'https://pre-dev-nowhere.lmnl.dev'),
  btcNetwork: bitcoin.networks.testnet,
  buildType: getConfig('buildType', 'vaults'),
  buildName:getConfig('buildName', 'Liminal'),
  buildFor: getConfig('buildFor', 'standard'),
  xpubConst: "043587cf",
  chainId: 4,
  chain: "rinkeby",
  env: "dev",
  liminalAppVersion: "20240428",
  writekey_segment: "AZn2eI044Bzaqa2oZ2X2GPNbSozaR6wc",
  configcat: "ktLaCCG1IEGP7oqBCJTT1A/7-0vwePQq0apXi6qQ6CCZQ",
  mmi: {
    apiUrl: "https://api.lmnl.dev",
    environment: "liminal-dev",
  },
  expressUrl: "https://lmnl.dev/go-to-liminal-express-stack",
  walletConnect: {
    PROJECT_ID: "abe09fa0f4f837c839d99742e70faac5",
    metadata: {
      name: "Liminal Vaults",
      description: "Liminal vaults client as a wallet",
      url: "https://www.lmnl.app/",
      icons: [],
    },
  },
  utxo_config: {
    BTC: {
      network: bitcoin.networks.testnet,
      coinPath: "1'",
      coin: "Testnet",
      decimalPlaces: 8,
      web3http: "https://rinkeby.infura.io/v3/6d75665a708f4ec2a62d31acb7f48105",
    },
  },
  evm_config: {
    ETH: {
      infura_url:
        "https://eth-sepolia.g.alchemy.com/v2/x5lU7xUm9FPjjDonCCovarS2y2sBMCZZ",
      gnosis_api: "https://safe-client.safe.global/v1/chains/11155111",
      web3http:
        "https://eth-sepolia.g.alchemy.com/v2/x5lU7xUm9FPjjDonCCovarS2y2sBMCZZ",
      nativecoin: "ETH",
      chainId: 11155111
    },
    TELOS: {
      infura_url:
        "https://testnet.telos.net/evm",
      gnosis_api: "https://gateway.safe.telos.net/v1/chains/41",
      web3http:
        "https://testnet.telos.net/evm",
      nativecoin: "TLOS",
      chainId: 41
    },
    BSC: {
      infura_url:
        "https://rpc.ankr.com/bsc/366ec22a2d25127898887885adc1ef841edf44bff903a3b60f533b73de2229ec/",
      gnosis_api: "https://safe-client.safe.global/v1/chains/56",
      web3http:
        "https://rpc.ankr.com/bsc/366ec22a2d25127898887885adc1ef841edf44bff903a3b60f533b73de2229ec/",
      nativecoin: "BNB",
      chainId: 56
    },
    POLYGON: {
      infura_url:
        "https://rpc.ankr.com/polygon/366ec22a2d25127898887885adc1ef841edf44bff903a3b60f533b73de2229ec",
      gnosis_api: "https://safe-client.safe.global/v1/chains/137",
      web3http:
        "https://rpc.ankr.com/polygon/366ec22a2d25127898887885adc1ef841edf44bff903a3b60f533b73de2229ec/",
      nativecoin: "MATIC",
      chainId: 137
    },
    XDAI: {
      infura_url: "https://rpc.gnosischain.com",
      web3http: "https://rpc.gnosischain.com",
      nativecoin: "xDai",
      gnosis_api: "https://safe-client.safe.global/v1/chains/100",
      chainId: 100
    },
    EWC: {
      infura_url: "https://rpc.energyweb.org",
      web3http: "https://rpc.energyweb.org",
      nativecoin: "EWT",
      gnosis_api: "https://safe-client.safe.global/v1/chains/246",
      chainId: 246
    },
    ARBITRUM: {
      infura_url: "https://arb1.arbitrum.io/rpc",
      web3http: "https://arb1.arbitrum.io/rpc",
      nativecoin: "AETH",
      gnosis_api: "https://safe-client.safe.global/v1/chains/42161",
      chainId: 42161
    },
    AVALANCHE: {
      infura_url:
        "https://rpc.ankr.com/avalanche/4b2763e373412067b180bf777ad9c87d5c6c7f1bfd6bd920db9a1cbf885263e2",
      web3http:
        "https://rpc.ankr.com/avalanche/4b2763e373412067b180bf777ad9c87d5c6c7f1bfd6bd920db9a1cbf885263e2",
      nativecoin: "AVAX",
      gnosis_api: "https://safe-client.safe.global/v1/chains/43114",
      chainId: 43114
    },
    OPTIMISM: {
      infura_url: "https://mainnet.optimism.io",
      web3http: "https://mainnet.optimism.io",
      nativecoin: "OETH",
      gnosis_api: "https://safe-client.safe.global/v1/chains/10",
      chainId: 10
    },
    FANTOM: {
      gnosis_api: "https://safe.fantom.network/v1/chains/250",
      infura_url:
        "https://ancient-little-waterfall.fantom.quiknode.pro/41ea138c7216e7ebc4947fda5e2c59fd26247497/",
      nativecoin: "FTM",
      web3http:
        "https://ancient-little-waterfall.fantom.quiknode.pro/41ea138c7216e7ebc4947fda5e2c59fd26247497/",
      chainId: 250
    },
    ETHW: {
      infura_url: "",
      gnosis_api: "",
      web3http: "https://mainnet.ethereumpow.org",
      nativecoin: "ETHW",
      chainId: 10001,
    },
    KLAY: {
      web3http: "https://api.baobab.klaytn.net:8651/",
      dataDecoderUrl: "",
      decimalPlaces: 18,
      explorer_link: "https://baobab.klaytnfinder.io/tx/",
      explorer_link_address: "https://baobab.klaytnfinder.io/account/address/",
      path: "m/44'/60'/0'/0/0",
      nativecoin: "KLAY",
      gnosis_api: "",
      iseip1559available: false,
      chainId: 1001,
    },
    XINFIN: {
      web3http: "https://apothem.xdcrpc.com/",
      dataDecoderUrl:
        "https://apothem.yplusvault.com:8001/v1/transactions/multisig_",
      decimalPlaces: 18,
      explorer_link: "https://apothem.blocksscan.io/tx/",
      explorer_link_address: "https://apothem.blocksscan.io/address/",
      path: "m/44'/60'/0'/0/0",
      nativecoin: "XDC",
      gnosis_api: "https://apothem.yplusvault.com:8000/api/v1",
      chainId: 51,
      networkChain: 51,
    },
    BAHAMUT: {
      web3http: "https://rpc1.oasis.bahamutchain.com/",
      decimalPlaces: 18,
      explorer_link: "https://oasis.ftnscan.com/tx/",
      explorer_link_address: "https://oasis.ftnscan.com/address/",
      path: "m/44'/60'/0'/0/0",
      nativecoin: "FTN",
      chainId: 4090,
      iseip1559available: true,
      gnosis_api: "",
      dataDecoderUrl: ""
    },
    PLAYA3ULL: {
      web3http: "https://api.mainnet.playa3ull.games/",
      decimalPlaces: 18,
      explorer_link: "https://explorer.playa3ull.games/blockchain/playa3ull/",
      explorer_link_address: "https://explorer.playa3ull.games/blockchain/playa3ull/address/",
      path: "m/44'/60'/0'/0/0",
      nativecoin: "3ULL",
      chainId: 3011,
      iseip1559available: true,
      gnosis_api: "",
      dataDecoderUrl: ""
    },
  },
  tron_config: {
    TRON: {
      decimalPlaces: 6,
      explorer_link: "https://shasta.tronscan.org/#/transaction/",
      explorer_link_address: "https://shasta.tronscan.org/#/address/",
      path: "m/44'/144'/0'/0/0",
      nativecoin: "TRX",
    },
  },
  dot_config: {
    DOT: {
      decimalPlaces: 10,
      explorer_link: "https://westend.subscan.io/extrinsic/",
      explorer_link_address: "https://westend.subscan.io/account/",
      path: "44'/354'/0'/0'/0'",
      nativecoin: "DOT",
      parentchain: "DOT",
      LEDGER_DEFAULT_ACCOUNT: 0x80000000,
      LEDGER_DEFAULT_CHANGE: 0x80000000,
      LEDGER_DEFAULT_INDEX: 0x80000000,
    },
  },
  sol_config: {
    SOL: {
      decimalPlaces: 9,
      explorer_link: "",
      explorer_link_address: "",
      path: "44'/501'/0'",
      nativecoin: "SOL",
      parentchain: "SOL",
      network: "devnet",
    },
  },
  xlm_config: {
    XLM: {
      explorer_link: "",
      explorer_link_address: "",
      path: "44'/148'/0'",
      parentchain: "XLM",
      nativecoin: "XLM",
      network: "testnet",
    },
  },

  xrp_config: {
    XRP: {
      infura_url:
        "https://misty-withered-darkness.bsc.quiknode.pro/71c2a380a6b77b3199ba99278bc05145a286e750/",
      gnosis_api: "https://safe-client.safe.global/v1/chains/56",
      web3http:
        "https://misty-withered-darkness.bsc.quiknode.pro/71c2a380a6b77b3199ba99278bc05145a286e750/",
      nativecoin: "XRP",
      PUBLIC_SERVER: "https://s.altnet.rippletest.net:51234/",
      path: "44'/144'/0'/0/0",
    },
  },
  cosmos_config: {
    "MALAGA-420": {
      decimalPlaces: 6,
      explorer_link:
        "https://block-explorer.malaga-420.cosmwasm.com/transactions/",
      explorer_link_address:
        "https://block-explorer.malaga-420.cosmwasm.com/account/",
      path: "m/44'/118'/0'/0/0",
      nativecoin: "MLG",
      denom: "UMLG",
      prefix: "wasm",
      parentchain: "COSMOS",
    },
    // "COSMOSHUB-4": {
    //   decimalPlaces: 6,
    //   explorer_link: 'https://atomscan.com/transactions/',
    //   explorer_link_address: 'https://atomscan.com/accounts/',
    //   path: "m/44'/118'/0'/0/0",
    //   nativecoin: "ATOM",
    //   denom: "UATOM",
    //   prefix: "cosmos",
    //   parentchain: "COSMOS",
    // },
    "THETA-TESTNET-001": {
      decimalPlaces: 6,
      explorer_link: "https://testnet.cosmos.bigdipper.live/transactions/",
      explorer_link_address: "https://testnet.cosmos.bigdipper.live/accounts/",
      path: "m/44'/118'/0'/0/0",
      nativecoin: "ATOM",
      denom: "UATOM",
      prefix: "cosmos",
      parentchain: "COSMOS",
      rpc: "https://rpc.sentry-01.theta-testnet.polypore.xyz:26657",
    },
  },
  DEFI_LIB_COMPOUND_ADDRESS: {
    DAI: "0x5592EC0cfb4dbc12D3aB100b257153436a1f0FEa",
    BAT: "0xbf7a7169562078c96f0ec1a8afd6ae50f12e5a99",
    ETH: "0xc778417E063141139Fce010982780140Aa0cD5Ab",
    REP: "0x6e894660985207feb7cf89Faf048998c71E8EE89",
    USDC: "0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b",
    USDT: "0xD9BA894E0097f8cC2BBc9D24D308b98e36dc6D02",
    WBTC: "0x577D296678535e4903D59A4C929B718e1D575e0A",
    ZRX: "0xddea378A6dDC8AfeC82C36E9b0078826bf9e68B6",
    cETH: "0xd6801a1dffcd0a410336ef88def4320d6df1883e",
  },
  staking_chain_list: [
    {
      chain: "ETH",
      assets: ["MATIC"],
    },
  ],
  MPC_Protocol_Support: {
    CARDANO: {
      signerType: ["MPC"],
    },
    ETH: {
      signerType: ["MULTI SIG", "MPC"],
    },
    BSC: {
      signerType: ["MULTI SIG", "MPC"],
    },
    POLYGON: {
      signerType: ["MULTI SIG", "MPC"],
    },
    XDAI: {
      signerType: ["MULTI SIG", "MPC"],
    },
    EWC: {
      signerType: ["MULTI SIG", "MPC"],
    },
    ARBITRUM: {
      signerType: ["MULTI SIG", "MPC"],
    },
    AVALANCHE: {
      signerType: ["MULTI SIG", "MPC"],
    },
    OPTIMISM: {
      signerType: ["MULTI SIG", "MPC"],
    },
    FANTOM: {
      signerType: ["MULTI SIG", "MPC"],
    },
    ETHW: {
      signerType: ["MULTI SIG", "MPC"],
    },
    KLAY: {
      signerType: ["MULTI SIG", "MPC"],
    },
    XINFIN: {
      signerType: ["MULTI SIG", "MPC"],
    },
    XLM: {
      signerType: ["MULTI SIG", "MPC"],
    },
    XRP: {
      signerType: ["MULTI SIG", "MPC"],
    },
    BNB: {
      signerType: ["MPC"],
    },
    "THETA-TESTNET-001": {
      signerType: ["MULTI SIG", "MPC"],
    },
    "MALAGA-420": {
      signerType: ["MULTI SIG", "MPC"],
    },
    TRON: {
      signerType: ["MULTI SIG", "MPC"],
    },
    SOL: {
      signerType: ["MPC"],
    },
    NEAR: {
      signerType: ["MPC"],
    },
    ZKEVM: {
      signerType: ["MPC"],
    },
    "BTC": {
      "signerType": ['MULTI SIG', 'MPC']
    },
    "BCH": {
      "signerType": ['MULTI SIG', 'MPC']
    },
    "DOGE": {
      "signerType": ['MULTI SIG', 'MPC']
    },
    "LTC": {
      "signerType": ['MULTI SIG', 'MPC']
    },
    "BSC_TEST": {
      "signerType": ['MPC']
    },
    "ALGORAND": {
      "signerType": ['MPC']
    },
    "BASE": {
      "signerType": ['MPC']
    },
    "BAHAMUT": {
      "signerType": ["MULTI SIG", "MPC"]
    },
    "TELOS": {
      "signerType": ["MPC","MULTI SIG"],
    },
    "BSV": {
      "signerType": ['MPC']
    },
    "PLAYA3ULL": {
      "signerType": ['MPC', "MULTI SIG"]
    }, 
  },
  smart_consolidation_supported_chains:['EVM', "TRON", "SOL"],

  getsafetxgasapi: "https://safe-relay.rinkeby.gnosis.io",
  appRestrictedaddresses: [
    {
      address: "0xe16C7165C8FeA64069802aE4c4c9C320783f2b6e",
      name: "COMP",
    },
    {
      address: "0x3d9819210A31b4961b30EF54bE2aeD79B9c9Cd3B",
      name: "Comptroller",
    },
    {
      address: "0xe65cdb6479bac1e22340e4e755fae7e509ecd06c",
      name: "cAAVE",
    },
    {
      address: "0x6C8c6b02E7b2BE14d4fA6022Dfd6d75921D90E4E",
      name: "cBAT",
    },
    {
      address: "0x70e36f6bf80a52b3b46b3af8e106cc0ed743e8e4",
      name: "cCOMP",
    },
    {
      address: "0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643",
      name: "cDAI",
    },
    {
      address: "0x4Ddc2D193948926D02f9B1fE9e1daa0718270ED5",
      name: "cETH",
    },
    {
      address: "0x7713DD9Ca933848F6819F38B8352D9A15EA73F67",
      name: "cFEI",
    },
    {
      address: "0xFAce851a4921ce59e912d19329929CE6da6EB0c7",
      name: "cLINK",
    },
    {
      address: "0x95b4ef2869ebd94beb4eee400a99824bf5dc325b",
      name: "cMKR",
    },
    {
      address: "0x158079Ee67Fce2f58472A96584A73C7Ab9AC95c1",
      name: "cREP",
    },
    {
      address: "0xF5DCe57282A584D2746FaF1593d3121Fcac444dC",
      name: "cSAI",
    },
    {
      address: "0x4b0181102a0112a2ef11abee5563bb4a3176c9d7",
      name: "cSUSHI",
    },
    {
      address: "0x12392F67bdf24faE0AF363c24aC620a2f67DAd86",
      name: "cTUSD",
    },
    {
      address: "0x35a18000230da775cac24873d00ff85bccded550",
      name: "cUNI",
    },
    {
      address: "0x39AA39c021dfbaE8faC545936693aC917d5E7563",
      name: "cUSDC",
    },
    {
      address: "0x041171993284df560249B57358F931D9eB7b925D",
      name: "cUSDP",
    },
    {
      address: "0xf650C3d88D12dB855b8bf7D11Be6C55A4e07dCC9",
      name: "cUSDT",
    },
    {
      address: "0xccF4429DB6322D5C611ee964527D42E5d685DD6a",
      name: "cWBTC",
    },
    {
      address: "0x80a2ae356fc9ef4305676f7a3e2ed04e12c33946",
      name: "cYFI",
    },
    {
      address: "0xB3319f5D18Bc0D84dD1b4825Dcde5d5f7266d407",
      name: "cZRX",
    },
    {
      address: "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45",
      name: "SwapRouter02",
    },
    {
      address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      name: "WETH",
    },
    {
      address: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
      name: "UniswapV2Router02",
    },
    {
      address: "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45",
      name: "UniswapV2Router2",
    },
    //  validator
    {
      address: "0x29f5406e20219f7e46806837e820cf7c2fa6d3ee",
      name: "Matic Foundation Node 4",
    },
    {
      address: "0xF5e574045298eE3A9870Ff712df9F9d325BcBB7A",
      name: "Matic Foundation Node 3",
    },
    {
      address: "0x1a9155ead92cd339e3ae789fd3a5dc60ae268634",
      name: "Matic Foundation Node 1",
    },
    {
      address: "0x15ed57ca28cbebb58d9c6c62f570046bc089bc66",
      name: "Infosys",
    },
    {
      address: "0x144070a59fe5e7ebe963ca59b500e60485c235b9",
      name: "Anonymous 19",
    },
  ],
  refillWallet_support_chain: [
    "XRP",
    "ETH",
    "BSC",
    "BNB",
    "POLYGON",
    "XDAI",
    "ARBITRUM",
    "AVALANCHE",
    "OPTIMISM",
    "FANTOM",
  ],
  autoSignerEmail: [
    "machine@lmnl.app",
    "signer@lmnl.app",
    "firstanswers@lmnl.app",
    "mpcsignerlmnl@lmnl.app",
  ],
};

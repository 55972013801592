<div class="d-inline-block" *ngIf="component_for == 'policy' && orgRole == 2" placement="top">
    <button appDisableClick id="CB_Whitelist_Policy" href="javascript:void(0);" [ngClass]="{'disabled' : _wallets?.length == 0}" [disabled]="_wallets?.length == 0"
        class="btn btn-primary btn-round btn-md ml-2" ngbTooltip="Add Policy" (click)="addPolicy(addPolicyModal)">
        <i class="icon-add-alt"></i>
        <span class="d-inline ml-1">Add</span>
    </button>
</div>

<button appDisableClick *ngIf="component_for == 'policy_list'" (click)="addPolicy(addPolicyModal);getChangePolicyData()"
    class="btn btn-link sign-btn p-0" data-original-title="Change Policy" ngbTooltip="Change Policy"
    type="submit">Change</button>

<button appDisableClick *ngIf="component_for == 'policy_pending'" (click)="addPolicy(addPolicyModal);approvePendingPolicy()"
    class="btn btn-primary btn-round btn-sm mr-2" data-original-title="Approve Policy" ngbTooltip="Approve Policy"
    type="submit">Approve</button>

<div *ngIf="addpolicy_component_for == 'policy_list' && orgRole == 2" placement="top">
    <button appDisableClick class="btn text-blue d-inline-flex align-items-center" (click)="addPolicy(addPolicyModal);selectWallet(singleWalletData)"
        [ngClass]="{'disabled': isWalletArchived }"
        [disabled]="isWalletArchived" >
        <i class="icon-add-alt mr-1"></i>
        <span class="d-inline ml-1 border-bottom-blue" [ngbTooltip]="addPolicyTooltip">Add Policy</span>
    </button>
</div>

<ng-template #addPolicyModal let-modal>
    <div class="modal-custom" *ngIf="step == 'step-0'">
        <div class="modal-header">
            <h2 class="modal-title">
                <i class="icon-shield mr-2"></i>
                <ng-container *ngIf="selectPolicyType == ''">Add Policy</ng-container>
                <ng-container *ngIf="selectPolicyType == 'Whitelist Address'">Add Whitelist Address</ng-container>
                <ng-container *ngIf="selectPolicyType == 'Blacklist Address'">Blacklist Address</ng-container>
                <ng-container *ngIf="selectPolicyType == 'Spending Limit' && !changePolicyData">Add Spending Limit</ng-container>
                <ng-container *ngIf="selectPolicyType == 'Transaction Limit' && !changePolicyData">Add Transaction Limit</ng-container>
                <ng-container *ngIf="selectPolicyType == 'Spending Limit' && changePolicyData">Change Spending Limit</ng-container>
                <ng-container *ngIf="selectPolicyType == 'Transaction Limit' && changePolicyData">Change Transaction Limit</ng-container>
            </h2>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="mb-4">
                        <div *ngIf="!selectPolicyType" class="mb-4" ngbDropdown #myDrop1="ngbDropdown">
                            <label>Policy</label>
                            <input autocomplete="off" class="form-control search-custom" placeholder="Select Policy"
                                id="dropdownManual" ngbDropdownAnchor (focus)="myDrop1.open()" type="text">
                            <ul ngbDropdownMenu  class="whitelistscroll w-100 py-0">
                                <div *ngIf="singleWalletData?.subtype !== 'hot'">
                                    <button id="CB_Whitelist_Address"
                                        class="dropdown-content card w-100 p-0 brd_Box m-0 input-bk"
                                        (click)="selectPolicy('Whitelist Address','../../../assets/images/policy/whitelist-address-icon-round.svg','Allow wallet members to send funds to this address.')">
                                        <li ngbDropdownItem class="p-3 text-wrap">
                                            <div class="d-flex align-items-center">
                                                <div>
                                                    <img class="w-42"
                                                        src="../../../assets/images/policy/whitelist-address-icon-round.svg" />
                                                </div>
                                                <div class="text-left">
                                                    <span class="d-block truncate_Name">Whitelist Address</span>
                                                    <span class="d-block t_Id">Allow wallet members to send funds to this address.</span>
                                                </div>
                                            </div>
                                        </li>
                                    </button>
                                </div>
                                <!-- <div>
                                    <button id="CB_Whitelist_Address"
                                        class="dropdown-content card w-100 p-0 brd_Box m-0"
                                        (click)="selectPolicy('Blacklist Address','../../../assets/images/policy/whitelist-address-icon-round.svg','Deny wallet member from sending funds to this address.')">
                                        <li ngbDropdownItem class="p-3 text-wrap">
                                            <div class="d-flex align-items-center">
                                                <div>
                                                    <img class="w-42"
                                                        src="../../../assets/images/policy/whitelist-address-icon-round.svg" />
                                                </div>
                                                <div class="text-left">
                                                    <span class="d-block truncate_Name">Blacklist Address</span>
                                                    <span class="d-block t_Id">Deny wallet member from sending funds to this address.</span>
                                                </div>
                                            </div>
                                        </li>
                                    </button>
                                </div> -->
                                <div *ngIf="singleWalletData?.subtype !== 'deposit'">
                                    <button id="CB_Spending_Limit" class="dropdown-content card input-bk w-100 p-0 brd_Box m-0"
                                        (click)="selectPolicy('Spending Limit','../../../assets/images/policy/velocity-icon-round.svg','Define the maximum USD value that can be sent in a given time period.')">
                                        <li ngbDropdownItem class="p-3 text-wrap">
                                            <div class="d-flex align-items-center">
                                                <div>
                                                    <img class="w-42"
                                                        src="../../../assets/images/policy/velocity-icon-round.svg" />
                                                </div>
                                                <div class="text-left">
                                                    <span class="d-block truncate_Name">Spending Limit</span>
                                                    <span class="d-block t_Id">Define the maximum USD value that can be sent in a given time period.</span>
                                                </div>
                                            </div>
                                        </li>
                                    </button>
                                </div>
                                <div *ngIf="singleWalletData?.subtype !== 'deposit'">
                                    <button id="CB_Transaction_Limit"
                                        class="dropdown-content card input-bk w-100 p-0 brd_Box m-0"
                                        (click)="selectPolicy('Transaction Limit','../../../assets/images/policy/thresold-icon-round.svg','Define the maximum USD value that can be sent in a single transaction.')">
                                        <li ngbDropdownItem class="p-3 text-wrap">
                                            <div class="d-flex align-items-center">
                                                <div>
                                                    <img class="w-42"
                                                        src="../../../assets/images/policy/thresold-icon-round.svg" />
                                                </div>
                                                <div class="text-left">
                                                    <span class="d-block truncate_Name">Transaction Limit</span>
                                                    <span class="d-block t_Id">Define the maximum USD value that can be sent in a single transaction.</span>
                                                </div>
                                            </div>
                                        </li>
                                    </button>
                                </div>
                            </ul>
                        </div>
                        <div *ngIf="selectPolicyType">
                            <div *ngIf="fromWalletConnect && selectPolicyType === 'Whitelist Address'" class="mt-auto text-center d-flex" style="height: 92px; background-color: #FAF1D3; border-radius: 12px;padding: 1rem;margin-bottom: 15px;">
                                <i class="icon-info mr-2 d-block" style="color: #746021; padding-top: 3px;"></i>
                                <span style="color: #746021;text-align: justify;">You are performing a DeFi action which  requires policy approval. Please request policy approval in the next screen. </span>
                            </div>
                            <div class="d-flex justify-content-between">
                                <label>Policy</label>
                                <a href="javascript:void(0);" class="btn-link text-muted text-underline"
                                    *ngIf="selectPolicyType && !changePolicyData"
                                    (click)="clearPolicyTypeSelect()">Clear</a>
                            </div>
                            <div class="card input-bk">
                                <div class="card-body card-body-border p-3">
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <img class="w-42" src="{{selectPolicyIcon}}" alt="img" />
                                        </div>
                                        <div class="text-left ml-3">
                                            <span class="d-block truncate_Name">{{selectPolicyType}}</span>
                                            <span class="d-block t_Id">{{selectPolicyMsg}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!selectWalletName && !selectGroupWallet" class="mb-4" ngbDropdown #myDrop="ngbDropdown">
                            <label>Wallet</label>
                            <input autocomplete="off" class="form-control search-custom" placeholder="Search Wallet"
                                id="dropdownManual" [(ngModel)]="searchWalletInput" ngbDropdownAnchor
                                (focus)="myDrop.open()" type="text">
                            <ul ngbDropdownMenu  class="whitelistscroll w-100 py-0">
                                <!-- <div>
                                    <button class="dropdown-content card w-100 p-0 brd_Box m-0"
                                        (click)="selectWallet(data,'groupWallet')">
                                        <li ngbDropdownItem class="p-3 text-wrap">
                                            <div class="d-flex align-items-center">
                                                <i class="icon-wallets text-dark mr-2 icon-round-border"></i>
                                                <div class="text-left">
                                                    <span class="d-block truncate_Name">Cold Wallets</span>
                                                    <div class="text-dark">
                                                        All Protocols
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </button>
                                </div>
                                <div>
                                    <button class="dropdown-content card w-100 p-0 brd_Box m-0"
                                        (click)="selectWallet(data,'groupWallet')">
                                        <li ngbDropdownItem class="p-3 text-wrap">
                                            <div class="d-flex align-items-center">
                                                <i class="icon-wallets text-dark mr-2 icon-round-border"></i>
                                                <div class="text-left">
                                                    <span class="d-block truncate_Name">Withdrawal Wallets</span>
                                                    <div class="text-dark">
                                                        All Protocols
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </button>
                                </div>
                                <div>
                                    <button class="dropdown-content card w-100 p-0 brd_Box m-0"
                                        (click)="selectWallet(data,'groupWallet')">
                                        <li ngbDropdownItem class="p-3 text-wrap">
                                            <div class="d-flex align-items-center">
                                                <i class="icon-wallets text-dark mr-2 icon-round-border"></i>
                                                <div class="text-left">
                                                    <span class="d-block truncate_Name">Deposit Wallets</span>
                                                    <div class="text-dark">
                                                        All Protocols
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </button>
                                </div>
                                <div>
                                    <button class="dropdown-content card w-100 p-0 brd_Box m-0"
                                        (click)="selectWallet(data,'groupWallet')">
                                        <li ngbDropdownItem class="p-3 text-wrap">
                                            <div class="d-flex align-items-center">
                                                <i class="icon-wallets text-dark mr-2 icon-round-border"></i>
                                                <div class="text-left">
                                                    <span class="d-block truncate_Name">Refill Wallets</span>
                                                    <div class="text-dark">
                                                        All Protocols
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </button>
                                </div> -->
                                <div *ngFor="let walletsdata of _wallets | wallet_search: searchWalletInput">
                                    <button class="dropdown-content card input-bk w-100 p-0 brd_Box m-0"
                                        (click)="selectWallet(walletsdata,'singleWallet')">
                                        <li ngbDropdownItem class="p-3 text-wrap">
                                            <div class="d-flex align-items-center">
                                                <div>
                                                    <img class="img-round-border"
                                                        [src]="walletsdata.chain | getIcon" />
                                                </div>
                                                <div class="text-left">
                                                    <span class="d-block truncate_Name">{{walletsdata.name}}</span>
                                                    <div
                                                        class="network text-dark {{ walletsdata.chain.toLowerCase() }}">
                                                        {{ walletsdata.chain | getName }}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </button>
                                </div>
                            </ul>
                        </div>
                        <div *ngIf="selectGroupWallet"> 
                            <div class="d-flex justify-content-between">
                                <label>Wallet</label>
                                <a href="javascript:void(0);" class="btn-link text-muted text-underline"
                                    *ngIf="selectGroupWallet && !changePolicyData"
                                    (click)="clearWalletSelect()">Clear</a>
                            </div>
                            <div class="card input-bk">
                                <div class="card-body card-body-border p-3">
                                    <div class="d-flex align-items-center">
                                        <i class="icon-wallets text-dark mr-2 icon-round-border"></i>
                                        <div class="text-left ml-3">
                                            <span class="d-block truncate_Name">Cold Wallets</span>
                                            <div class="text-dark">All Protocols </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="selectWalletName">
                            <div class="d-flex justify-content-between">
                                <label>Wallet</label>
                                <a href="javascript:void(0);" class="btn-link text-muted text-underline"
                                    *ngIf="selectWalletName && !changePolicyData"
                                    (click)="clearWalletSelect()">Clear</a>
                            </div>
                            <div class="card input-bk">
                                <div class="card-body card-body-border p-3">
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <img class="img-round-border" [src]="selectWalletIcon | safe" alt="img" />
                                        </div>
                                        <div class="text-left ml-3">
                                            <span class="d-block truncate_Name">{{selectWalletName}}</span>
                                            <div class="network text-dark {{ selectWalletCoin.toLowerCase() }}">
                                                {{ selectWalletCoin | getName }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Protocol -->
                        <!-- <div *ngIf="selectGroupWallet">
                            <label>Protocol</label>
                            <select class="form-control text-dark" (change)="selectProtocol($event.target.value);">
                                <option disabled selected>Select Protocol</option>
                                <option *ngFor="let item of protocol" value="{{item.chain}}">{{item.chain | getName}}</option>
                              </select>
                        </div> -->
                        <div ngbDropdown #myDrop="ngbDropdown" *ngIf="selectGroupWallet && !selected_protocol_value">
                            <label>Protocol</label>
                            <input autocomplete="off" class="form-control search-custom" [(ngModel)]="coin"
                                placeholder="Select protocol" ngbDropdownAnchor (focus)="myDrop.open()" type="text">
                            <ul ngbDropdownMenu class="digital_asset w-100 py-0">
                                <div *ngFor="let t of profileData.keys | keyvalue | search_coin: coin ">
                                    <button class="dropdown-content card w-100 mb-0 p-0 brd_Box"
                                        (click)="selectProtocol(t.key,t)">
                                        <li ngbDropdownItem class="p-3">
                                            <div class="align-items-center">
                                                <div class="media border-after-xs align-items-center">
                                                    <div class="d-flex align-items-center box_Circle_Qr p-2">
                                                        <span class="network text-dark text-left  font-size-14"
                                                            [ngClass]="t.key.toLowerCase() "></span>
                                                    </div>
                                                    <div class="d-flex flex-column text-left">
                                                        <span #name class="m-0 truncate_Name font-size-14 ">{{
                                                            getName(t.key) }}</span>
                                                        <span class="text-muted font-size-12">{{
                                                            getSupportedCoins(t.key)&& getSupportedCoins(t.key).length > 0 ?
                                                            getSupportedCoinGroupText(t.key) : getName(t.key) |
                                                            titlecase}} </span>
                                                    </div>
                                                    <div class="media-body text-right">
                                                        <div class="position-relative d-flex justify-content-end ml-3">
                                                            <ul class="d-flex assets-group ml-2">
                                                                <li>
                                                                    <span>
                                                                        <img class="img-fluid for-light img-round-border bg-white"
                                                                            [src]="t.key | getIcon" alt="">
                                                                    </span>
                                                                </li>
                                                                <li
                                                                    *ngIf="getSupportedCoins(t.key)&& getSupportedCoins(t.key).length>0">
                                                                    <span
                                                                        *ngIf="getSupportedCoins(t.key)&& getSupportedCoins(t.key).length>0"
                                                                        class="mr-1 img-round-border last_count text-primary">
                                                                        + {{getSupportedCoins(t.key).length - 1}}
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </button>
                                </div>
                            </ul>
                        </div>
                        <div *ngIf="selected_protocol_value">
                            <div class="d-flex justify-content-between">
                                <label>Protocol</label>
                                <a href="javascript:void(0);" class="btn-link text-muted text-underline" (click)="selected_protocol_value = !selected_protocol_value;selectInterWalletAddress = !selectInterWalletAddress">Clear</a>
                            </div>
                            <div class="card card-border mb-0">
                                <div class="card-body">
                                    <div class="align-items-center">
                                        <div class="media border-after-xs align-items-center">
                                            <div class="d-flex align-items-center box_Circle_Qr p-2">
                                                <span class="network text-dark text-left  font-size-14"
                                                    [ngClass]="selected_protocol_value.toLowerCase() "></span>
                                            </div>
                                            <div class="d-flex flex-column text-left">
                                                <span #name class="m-0 truncate_Name font-size-14 ">{{
                                                    getName(selected_protocol_value) }}</span>
                                                <span class="text-muted font-size-12">{{
                                                    getSupportedCoins(selected_protocol_value)&&
                                                    getSupportedCoins(selected_protocol_value).length > 0 ?
                                                    getSupportedCoinGroupText(selected_protocol_value) :
                                                    getName(selected_protocol_value) |
                                                    titlecase}} </span>
                                            </div>
                                            <div class="media-body text-right">
                                                <div class="position-relative d-flex justify-content-end ml-3">
                                                    <ul class="d-flex assets-group ml-2">
                                                        <li>
                                                            <span>
                                                                <img class="img-fluid for-light img-round-border bg-white"
                                                                    [src]="selected_protocol_value | getIcon" alt="">
                                                            </span>
                                                        </li>
                                                        <li
                                                            *ngIf="getSupportedCoins(selected_protocol_value)&& getSupportedCoins(selected_protocol_value).length>0">
                                                            <span
                                                                *ngIf="getSupportedCoins(selected_protocol_value)&& getSupportedCoins(selected_protocol_value).length>0"
                                                                class="mr-1 img-round-border last_count text-primary">
                                                                + {{getSupportedCoins(selected_protocol_value).length - 1}}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="(selectPolicyType == 'Whitelist Address' || selectPolicyType == 'Blacklist Address') && (selectWalletName || selectGroupWallet)">
                        <div class="mb-4" *ngIf="selectPolicyType == 'Whitelist Address'">
                            <div class="d-flex">
                              <button class="type-pill-button" [ngClass]="{ 'active': addressTxType == 'external_address'}" (click)="selectAddressType('external_address')">
                                External Address 
                              </button>
                              <button class="type-pill-button" [ngClass]="{ 'active': addressTxType == 'internal_address'}" (click)="selectAddressType('internal_address')">
                                Internal Address
                              </button>
                            </div>
                          </div>
                          <ng-container *ngIf="addressTxType == 'external_address'">
                            <div class="form-group mb-4">
                                <div class="d-inline">
                                    <label>Label</label>
                                </div>
                                <input autocomplete="off" #inputPolicyLabel (keyup)="setPolicyLabel(inputPolicyLabel)"
                                    value="{{policyLabel}}" class="form-control" placeholder="Enter Label" type="text"
                                   >
                            </div>
                            <div class="form-group mb-4">
                                <div class="d-inline">
                                    <label>Address</label>
                                </div>
                                <input autocomplete="off" [(ngModel)]="inputPolicyAddress" (keyup)="setPolicyAddress(inputPolicyAddress)"
                                    class="form-control" [ngClass]="{'is-invalid': !whitelistNew}" placeholder="Enter Address" type="text">
                                   
                                <div class="invalid-feedback" *ngIf="!whitelistNew">Address already whitelisted</div>
                                <!-- <div class="invalid-feedback" *ngIf="addressInvalid">Invalid address format for the specified network</div> -->
                            </div>
                            <ng-container *ngIf="showMemo" [ngTemplateOutlet]="memoTag"></ng-container>
                            <div *ngIf="selectWalletName && trmStatus">
                                <app-aml [walletID]="policyWalletId" [chain]="selectWalletCoin?.toLowerCase()" [address]="inputPolicyAddress" [component_for]="'Whitelist_Policy'" (amlStatus)=onAmlResponse($event)></app-aml>
                            </div>
                            <div *ngIf="selectGroupWallet && trmStatus">
                                <app-aml [walletID]="policyWalletId" [chain]="addressWallets[0]?.chain.toLowerCase()" [address]="inputPolicyAddress" [component_for]="'Whitelist_Policy'" (amlStatus)=onAmlResponse($event)></app-aml>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="addressTxType == 'internal_address'">
                            <div *ngIf="!selectInterWalletAddress" class="mb-4" ngbDropdown #myDrop="ngbDropdown">
                                <label>Wallet</label>
                                <input autocomplete="off" class="form-control search-custom" placeholder="Search Wallet"
                                    id="dropdownManual" [(ngModel)]="searchWalletInput" ngbDropdownAnchor
                                    (focus)="myDrop.open()" type="text">
                                <ul ngbDropdownMenu  class="whitelistscroll w-100 py-0">
                                    <div *ngFor="let walletData of addressWallets_internal | wallet_search: searchWalletInput">
                                        <button class="dropdown-content card w-100 p-0 brd_Box m-0"
                                            (click)="selectInternalWallet(walletData)">
                                            <li ngbDropdownItem class="p-3 text-wrap">
                                                <div class="d-flex align-items-center">
                                                    <div>
                                                        <img class="img-round-border"
                                                            [src]="walletData.chain | getIcon" />
                                                    </div>
                                                    <div class="text-left">
                                                        <span class="d-block truncate_Name">{{walletData.name}}</span>
                                                        <div
                                                            class="network text-dark {{ walletData.chain.toLowerCase() }}">
                                                            {{ walletData.chain | getName }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </button>
                                    </div>
                                </ul>
                            </div>
                            <div *ngIf="selectInterWalletAddress">
                                <div class="d-flex justify-content-between">
                                    <label>Wallet</label>
                                    <a href="javascript:void(0);" class="btn-link text-muted text-underline"
                                        *ngIf="selectInterWalletAddress"
                                        (click)="resetInternalWallet()">Clear</a>
                                </div>
                                <div class="card input-bk">
                                    <div class="card-body card-body-border p-3">
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <img class="img-round-border" [src]="selectInterWalletAddress.chain | getIcon" alt="img" />
                                            </div>
                                            <div class="text-left ml-3">
                                                <span class="d-block truncate_Name">{{selectInterWalletAddress.name}}</span>
                                                <div class="network text-dark {{ selectInterWalletAddress.chain.toLowerCase() }}">
                                                    {{ selectInterWalletAddress.chain | getName }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="invalid-feedback" *ngIf="!whitelistNew_internal">Address already whitelisted</div>
                                </div>
                            </div>
                            <ng-container *ngIf="showMemo" [ngTemplateOutlet]="memoTag"></ng-container>
                            <div *ngIf="selectWalletName && trmStatus">
                                <app-aml [walletID]="policyWalletId" [chain]="selectWalletCoin?.toLowerCase()" [address]="selectInterWalletAddress?.address" [component_for]="'Whitelist_Policy'" (amlStatus)=onAmlResponse($event)></app-aml>
                            </div>
                            <div *ngIf="selectGroupWallet && trmStatus">
                                <app-aml [walletID]="policyWalletId" [chain]="addressWallets[0]?.chain.toLowerCase()" [address]="selectInterWalletAddress?.address" [component_for]="'Whitelist_Policy'" (amlStatus)=onAmlResponse($event)></app-aml>
                            </div>
                          </ng-container>
                          <ng-template #memoTag>
                            <div class="form-group mb-4">
                                <div class="d-inline">
                                    <label>Memo/Tag (optional)</label>
                                </div>
                                <input autocomplete="off" (keypress)="memoValidation.validation == 'numeric'?allowNumber($event):''" [(ngModel)]="inputPolicyMemo" (change)="onMemoChange()" class="form-control" placeholder="Enter memo/tag"  type="text">
                                <div *ngIf="!isMemoValidator.isValid" class="mt-2 error-message">Invalid Memo Value, {{isMemoValidator.message}}</div>
                            </div>
                          </ng-template>
                    </div>
                    <div *ngIf="selectPolicyType == 'Spending Limit' && (selectWalletName || selectGroupWallet)">
                        <div class="d-inline">
                            <label>Period</label>
                        </div>
                        <div class=" justify-content-between">
                            <div class="input-group">
                                <input type="number" autocomplete="off" min="1" (keypress)="allowNumber($event)" onwheel="return false;" class="form-control text-dark border-radius-left" value="{{customPolicyTimePeriod}}" class="form-control small-input border-radius-left"
                                #inputpolicyTimePeriod (input)="selectTimePeriod(inputpolicyTimePeriod)">
                                <div class="input-group-append">
                                  <div ngbDropdown class="d-inline-flex align-items-center input-group-text input-bk input-border">
                                    <button class="btn p-0 text-dark text-capitalize" id="dropdownBasicTime" ngbDropdownToggle>
                                      {{interval_unit}}
                                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 8 5" fill="none"
                                        class="ml-2">
                                        <path
                                          d="M7.52555 0.380031C7.39796 0.285101 7.23385 0.239397 7.06898 0.252876C6.90411 0.266356 6.75184 0.337925 6.64534 0.451994L3.99996 3.3086L1.35454 0.452041C1.24834 0.337525 1.09595 0.265666 0.930887 0.252273C0.765823 0.23888 0.601608 0.285051 0.474368 0.380627C0.347127 0.476203 0.267284 0.613356 0.252404 0.761914C0.237523 0.910471 0.288823 1.05827 0.395019 1.17278L3.5202 4.54793C3.57882 4.61121 3.65218 4.66211 3.73509 4.69704C3.81799 4.73198 3.90842 4.75008 3.99996 4.75008C4.0915 4.75008 4.18192 4.73198 4.26482 4.69704C4.34773 4.66211 4.42109 4.61121 4.47972 4.54793L7.60489 1.17273C7.71105 1.0581 7.76232 0.910236 7.74745 0.761609C7.73257 0.612982 7.65276 0.475744 7.52555 0.380031Z"
                                          fill="#6F757A" />
                                      </svg>
                                    </button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasicTime" class="p-0 custom-dropdown-menu">
                                      <button class="p-2 justify-content-start border-0 rounded-0" ngbDropdownItem
                                        (click)="selectRole('hours')">Hours</button>
                                      <button class="p-2 justify-content-start border-0 rounded-0" ngbDropdownItem
                                        (click)="selectRole('days')">Days</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                        </div>
                        <div class="mt-3">
                            <div class="d-inline">
                                <label>Amount</label>
                            </div>
                            <div class="input-group">
                                <input autocomplete="off" class="form-control text-center font-weight-bold border-radius-left"
                                    placeholder="Enter Amount" type="number" onwheel="return false;" autocomplete="off" min="1" (keypress)="allowNumberDecimal($event)"
                                    #inputpolicyAmount [(ngModel)]="policyAmount">
                                <div class="input-group-append"><span class="input-group-text font-size-14 input-bk input-border">USD</span>
                                </div>
                            </div>
                        </div>
                        <label class="text-muted small mt-1">Note: Policy conditions are set in USD. The amount is calculated as cumulative total of all the native tokens/coins held in the wallet.</label>
                    </div>
                    <div *ngIf="selectPolicyType == 'Transaction Limit' && (selectWalletName || selectGroupWallet)">
                        <div class="d-inline">
                            <label>Amount</label>
                        </div>
                        <div class="input-group">
                            <input autocomplete="off" class="form-control body-font text-center font-weight-bold border-radius-left" onwheel="return false;" (keypress)="allowNumberDecimal($event)" type="number" min="1" [(ngModel)]="policyAmount" #inputpolicyAmount>
                            <div class="input-group-append"><span class="input-group-text font-size-14 input-bk input-border body-font">USD</span></div>
                        </div>
                        <label class="text-muted small mt-1">Note: Policy conditions are set in USD. The amount is calculated as cumulative total of all the native tokens/coins held in the wallet.</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button *ngIf="nextButtonAll" [ngClass]="{'disabled': !selectPolicyType || !selectWalletName }"
                [disabled]="!selectPolicyType || !selectWalletName"type="submit"
                class="btn btn-outline-primary w-100">Next</button>
            <button *ngIf="selectPolicyType == 'Whitelist Address' && addressTxType == 'external_address'"
                [ngClass]="{'disabled': !selectPolicyType || (!selectWalletName && !selectGroupWallet) || !policyLabel || !policyAddress || !isMemoValidator.isValid || !whitelistNew && addressTxType == 'external_address'}"
                [disabled]="!selectPolicyType ||  (!selectWalletName && !selectGroupWallet) || !policyLabel || !policyAddress || !isMemoValidator.isValid || !whitelistNew && addressTxType == 'external_address'"
               type="submit" (click)="addPolicyNext()" class="btn btn-outline-primary w-100">Next</button>
            <button *ngIf="selectPolicyType == 'Whitelist Address' && addressTxType == 'internal_address'"
                [ngClass]="{'disabled': !selectPolicyType || (!selectWalletName && !selectGroupWallet) || !selectInterWalletAddress || !isMemoValidator.isValid || !whitelistNew_internal && addressTxType == 'internal_address'}"
                [disabled]="!selectPolicyType ||  (!selectWalletName && !selectGroupWallet) || !selectInterWalletAddress || !isMemoValidator.isValid || !whitelistNew_internal && addressTxType == 'internal_address'"
               type="submit" (click)="addPolicyNext()" class="btn btn-outline-primary w-100">Next</button>
            <button *ngIf="selectPolicyType == 'Blacklist Address'"
                [ngClass]="{'disabled': !selectPolicyType || (!selectWalletName && !selectGroupWallet) || !policyLabel || !policyAddress || !whitelistNew}"
                [disabled]="!selectPolicyType ||  (!selectWalletName && !selectGroupWallet) || !policyLabel || !policyAddress || !whitelistNew"
               type="submit" (click)="addPolicyNext()" class="btn btn-outline-primary w-100">Next</button>   
            <button *ngIf="selectPolicyType == 'Spending Limit'"
                [ngClass]="{'disabled': !selectPolicyType || !selectWalletName || !policyTimePeriod || !policyAmount}"
                [disabled]="!selectPolicyType || !selectWalletName || !policyTimePeriod || !policyAmount" title=""
                type="submit" (click)="addPolicyNext()" class="btn btn-outline-primary w-100">Next</button>
            <button *ngIf="selectPolicyType == 'Transaction Limit'"
                [ngClass]="{'disabled': !selectPolicyType || !selectWalletName || !policyAmount}"
                [disabled]="!selectPolicyType || !selectWalletName|| !policyAmount"type="submit"
                (click)="addPolicyNext()" class="btn btn-outline-primary w-100">Next</button>
        </div>
    </div>
    <div class="modal-custom" *ngIf="step == 'step-1'">
        <div class="modal-header">
            <h2 class="modal-title"><i class="icon-shield mr-2"></i> 
                <ng-container *ngIf="selectPolicyType == 'Whitelist Address'">Add Whitelist Address</ng-container>
                <ng-container *ngIf="selectPolicyType == 'Blacklist Address'">Add Blacklist Address</ng-container>
            </h2>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <label>Whitelist Address In</label>
                    <div *ngIf="selectWalletName">
                        <div class="card input-bk">
                            <div class="card-body card-body-border p-3">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <img class="img-round-border" src="{{selectWalletIcon}}" alt="img" />
                                    </div>
                                    <div class="text-left ml-3">
                                        <span class="d-block truncate_Name">{{selectWalletName}}</span>
                                        <div class="network text-dark {{ selectWalletCoin.toLowerCase() }}">
                                            {{ selectWalletCoin | getName }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="selectGroupWallet"> 
                        <div class="card input-bk">
                            <div class="card-body card-body-border p-3">
                                <div class="d-flex align-items-center">
                                    <i class="icon-wallets text-dark mr-2 icon-round-border"></i>
                                    <div class="text-left ml-3">
                                        <span class="d-block truncate_Name">Cold Wallets</span>
                                        <div class="text-dark">All Protocols </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column align-items-center justify-content-center min-height-center">
                        <div class="policy-type-icon-big">
                            <img src="{{selectPolicyIcon}}" alt="img" />
                        </div>
                        <span class="d-block font-weight-bold font-size-16 mt-3">{{policyLabel}}</span>
                        <div class="mb-3">
                            <p class="d-block mt-2 text-break text-center mb-0">{{policyAddress}}</p>
                            <p *ngIf="inputPolicyMemo" class="d-block mt-1 text-break text-center">Memo/Tag - {{inputPolicyMemo}}</p>
                        </div>
                        <p class="text-center text-muted mt-3">Whitelist policy requires wallet members to approve
                            address addition as per wallet configuration.</p>
                    </div>
                </div>
            </div>

        </div>
        <div class="modal-footer border-0">
            <button type="submit" class="btn btn-outline-primary w-100" (click)="confirmPolicy();createPolicy()"><i
                    class="icon-check mr-1"></i> Approve</button>
        </div>
    </div>
    <div class="modal-custom" *ngIf="step == 'step-1.1'">
        <div class="modal-header">
            <h2 class="modal-title">
                <i class="icon-shield mr-2"></i>
                <ng-container *ngIf="selectPolicyType == 'Whitelist Address'">Approve Whitelist Address</ng-container>
                <ng-container *ngIf="approveCommonTitle">Approve Whitelist Address</ng-container>
                <ng-container *ngIf="approveRemoveTitle">Remove Whitelist Address</ng-container>
                <ng-container *ngIf="selectPolicyType == 'Blacklist Address'">Approve Blacklist Address</ng-container>
            </h2>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <label>Whitelist Address In</label>
                    <div *ngIf="selectWalletName">
                        <div class="card input-bk m-0">
                            <div class="card-body card-body-border p-3">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <img class="img-round-border" src="{{selectWalletIcon}}" alt="img" />
                                    </div>
                                    <div class="text-left ml-3">
                                        <span class="d-block truncate_Name">{{selectWalletName}}</span>
                                        <div class="network text-dark {{ selectWalletCoin.toLowerCase() }}">
                                            {{ selectWalletCoin | getName }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="selectGroupWallet"> 
                        <div class="card input-bk">
                            <div class="card-body card-body-border p-3">
                                <div class="d-flex align-items-center">
                                    <i class="icon-wallets text-dark mr-2 icon-round-border"></i>
                                    <div class="text-left ml-3">
                                        <span class="d-block truncate_Name">Cold Wallets</span>
                                        <div class="text-dark">All Protocols </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column align-items-center justify-content-center min-height-center">
                        <div class="policy-type-icon-big">
                            <img src="{{selectPolicyIcon}}" alt="img" />
                        </div>
                        <span class="d-block font-weight-bold font-size-16 mt-3">{{policyLabel}}</span>
                        <div class="mb-3">
                            <p class="d-block mt-2 mb-0 text-break text-center" *ngIf="pendingPolicyData?.sub_type?.toLowerCase() != 'enable_app'">{{policyAddress}}</p>
                            <p class="d-block text-break text-center" *ngIf="selectedPolicyMemo">Memo/Tag - {{selectedPolicyMemo}}</p>
                        </div>
                        <span class="d-block mt-2" *ngIf="pendingPolicyData?.sub_type?.toLowerCase() == 'enable_app'">You are enabling {{policyLabel}} for {{selectWalletName}}</span>
                        <!-- <div *ngIf="ledgerConnect" class="d-flex align-items-center mt-5">
                            <h2 class="mb-0 mr-1 font-size-20">
                                <i class="icon-ledger text-dark"></i>
                            </h2>
                            <div class="ml-2">
                                <span class="d-block">
                                    <a href="javascript:void(0);" class="font-size-13">
                                        Follow the instructions on your Ledger device to Sign
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="!ledgerConnect" class="d-flex align-items-center mt-3">
                            <h2 class="mb-0 mr-1 font-size-20">
                                <i class="icon-trezor text-dark"></i>
                            </h2>
                            <div class="ml-2">
                                <span class="d-block">
                                    <a href="javascript:void(0);" class="font-size-13">
                                        Follow the instructions on your Trezor device to Sign
                                    </a>
                                </span>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="modal-footer border-0" *ngIf="browser == 'Firefox' && deviceName == 'ledger'; else not_fox_a" >
            <div class="text-dark text-center w-100 row py-2">Firefox does not support signing with Ledger. Please use Google Chrome or other supported browser.</div>
        </div> -->
        <!-- <ng-template #not_fox_a> -->
            <div class="modal-footer border-0">
                <button *ngIf="!pendingPolicyData" type="submit" class="btn btn-primary w-100"
                    (click)="createPolicy()">
                    <i *ngIf="!isSigning" class="icon-check mr-1"></i>
                    <div *ngIf="isSigning" class="loader-box">
                        <div class="loader-15 bg-white"></div>
                    </div>
                    <span *ngIf="!isSigning">Approve</span>
                </button>
                <button *ngIf="pendingPolicyData" type="submit" class="btn btn-primary w-100"
                    (click)="signPendingPolicy()">
                    <i *ngIf="!isSigning" class="icon-check mr-1"></i>
                    <div *ngIf="isSigning" class="loader-box">
                        <div class="loader-15 bg-white"></div>
                    </div>
                    <span *ngIf="!isSigning">Approve</span>
                </button>
            </div>
        <!-- </ng-template> -->
    </div>
    <div class="modal-custom" *ngIf="step == 'step-2'">
        <div class="modal-header">
            <h2 class="modal-title">
                <i class="icon-shield mr-2"></i>
                <ng-container *ngIf="selectPolicyType == 'Spending Limit' && !changePolicyData">Add Spending Limit</ng-container>
                <ng-container *ngIf="selectPolicyType == 'Spending Limit' && changePolicyData">Change Spending Limit</ng-container>
            </h2>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <label>Spending Limit In</label>
                    <div *ngIf="selectWalletName">
                        <div class="card input-bk">
                            <div class="card-body card-body-border p-3">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <img class="img-round-border" src="{{selectWalletIcon}}" alt="img" />
                                    </div>
                                    <div class="text-left ml-3">
                                        <span class="d-block truncate_Name">{{selectWalletName}}</span>
                                        <div class="network text-dark {{ selectWalletCoin.toLowerCase() }}">
                                            {{ selectWalletCoin | getName }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column align-items-center justify-content-center min-height-center">
                        <div class="policy-type-icon-big">
                            <img src="{{selectPolicyIcon}}" alt="img" />
                        </div>
                        <span class="d-block font-weight-bold font-size-16 mt-3">{{policyAmount | number : '1.2-2'}}
                            USD</span>
                        <span class="d-block mt-2">Every {{policyTimePeriod}} period</span>
                        <p class="text-center text-muted mt-3">Spending Limit requires wallet members to approve the
                            request as per wallet configuration.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button type="submit" class="btn btn-outline-primary w-100" (click)="confirmPolicy();createPolicy()"><i
                    class="icon-check mr-1"></i> Approve</button>
        </div>
    </div>
    <div class="modal-custom" *ngIf="step == 'step-2.1'">
        <div class="modal-header">
            <h2 class="modal-title">
                <i class="icon-shield mr-2"></i>
                <ng-container *ngIf="selectPolicyType == 'Spending Limit' && !changePolicyData">Approve Spending Limit</ng-container>
                <ng-container *ngIf="selectPolicyType == 'Spending Limit' && changePolicyData">Approve Spending Limit</ng-container>
                <ng-container *ngIf="approveCommonTitle">Approve Spending Limit</ng-container>
                <ng-container *ngIf="approveRemoveTitle">Remove Spending Limit</ng-container>
            </h2>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <label>Spending Limit In</label>
                    <div *ngIf="selectWalletName">
                        <div class="card input-bk m-0">
                            <div class="card-body card-body-border p-3">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <img class="img-round-border" src="{{selectWalletIcon}}" alt="img" />
                                    </div>
                                    <div class="text-left ml-3">
                                        <span class="d-block truncate_Name">{{selectWalletName}}</span>
                                        <div class="network text-dark {{ selectWalletCoin.toLowerCase() }}">
                                            {{ selectWalletCoin | getName }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column align-items-center justify-content-center min-height-center">
                        <div class="policy-type-icon-big">
                            <img src="{{selectPolicyIcon}}" alt="img" />
                        </div>
                        <span class="d-block font-weight-bold font-size-16 mt-3">{{policyAmount | number : '1.2-2'}}
                            USD</span>
                        <span class="d-block mt-2">Every {{policyTimePeriod}} period</span>
                        <!-- <div *ngIf="ledgerConnect" class="d-flex align-items-center mt-3">
                            <h2 class="mb-0 mr-1 font-size-20">
                                <i class="icon-ledger text-dark"></i>
                            </h2>
                            <div class="ml-2">
                                <span class="d-block">
                                    <a href="javascript:void(0);" class="font-size-13">
                                        Follow the instructions on your Ledger device to Sign
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="!ledgerConnect" class="d-flex align-items-center mt-3">
                            <h2 class="mb-0 mr-1 font-size-20">
                                <i class="icon-trezor text-dark"></i>
                            </h2>
                            <div class="ml-2">
                                <span class="d-block">
                                    <a href="javascript:void(0);" class="font-size-13">
                                        Follow the instructions on your Trezor device to Sign
                                    </a>
                                </span>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="modal-footer border-0" *ngIf="browser == 'Firefox' && deviceName == 'ledger'; else not_fox_b" >
            <div class="text-dark text-center w-100 row py-2">Firefox does not support signing with Ledger. Please use Google Chrome or other supported browser.</div>
        </div> -->
        <!-- <ng-template #not_fox_b> -->
            <div class="modal-footer border-0">
                <button *ngIf="!pendingPolicyData"type="submit" class="btn btn-primary w-100"
                    (click)="createPolicy()">
                    <i *ngIf="!isSigning" class="icon-check mr-1"></i>
                    <div *ngIf="isSigning" class="loader-box">
                        <div class="loader-15 bg-white"></div>
                    </div>
                    <span *ngIf="!isSigning">Approve</span>
                </button>
                <button *ngIf="pendingPolicyData"type="submit" class="btn btn-primary w-100"
                    (click)="signPendingPolicy()">
                    <i *ngIf="!isSigning" class="icon-check mr-1"></i>
                    <div *ngIf="isSigning" class="loader-box">
                        <div class="loader-15 bg-white"></div>
                    </div>
                    <span *ngIf="!isSigning">Approve</span>
                </button>
            </div>
        <!-- </ng-template> -->
    </div>
    <div class="modal-custom" *ngIf="step == 'step-3'">
        <div class="modal-header">
            <h2 class="modal-title">
                <i class="icon-shield mr-2"></i>
                <ng-container *ngIf="selectPolicyType == 'Transaction Limit' && !changePolicyData">Add Transaction Limit</ng-container>
                <ng-container *ngIf="selectPolicyType == 'Transaction Limit' && changePolicyData">Change Transaction Limit</ng-container>
            </h2>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <label>Transaction Limit In</label>
                    <div *ngIf="selectWalletName">
                        <div class="card input-bk">
                            <div class="card-body card-body-border p-3">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <img class="img-round-border" src="{{selectWalletIcon}}" alt="img" />
                                    </div>
                                    <div class="text-left ml-3">
                                        <span class="d-block truncate_Name">{{selectWalletName}}</span>
                                        <div class="network text-dark {{ selectWalletCoin.toLowerCase() }}">
                                            {{ selectWalletCoin | getName }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column align-items-center justify-content-center min-height-center">
                        <div class="policy-type-icon-big">
                            <img src="{{selectPolicyIcon}}" alt="img" />
                        </div>
                        <span class="d-block font-weight-bold font-size-16 mt-3">{{policyAmount | number : '1.2-2'}}
                            USD</span>
                        <span class="d-block mt-2">Per transaction</span>
                        <p class="text-center text-muted mt-3">Transaction Limit requires wallet members to approve the
                            request as per wallet configuration.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button type="submit" class="btn btn-outline-primary w-100" (click)="confirmPolicy();createPolicy()"><i
                    class="icon-check mr-1"></i> Approve</button>
        </div>
    </div>
    <div class="modal-custom" *ngIf="step == 'step-3.1'">
        <div class="modal-header">
            <h2 class="modal-title">
                <i class="icon-shield mr-2"></i>
                <ng-container *ngIf="selectPolicyType == 'Transaction Limit' && !changePolicyData">Approve Transaction Limit</ng-container>
                <ng-container *ngIf="selectPolicyType == 'Transaction Limit' && changePolicyData">Approve Transaction Limit</ng-container>
                <ng-container *ngIf="approveCommonTitle">Approve Transaction Limit</ng-container>
                <ng-container *ngIf="approveRemoveTitle">Remove Transaction Limit</ng-container>
            </h2>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <label>Transaction Limit In</label>
                    <div *ngIf="selectWalletName">
                        <div class="card input-bk m-0">
                            <div class="card-body card-body-border p-3">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <img class="img-round-border" src="{{selectWalletIcon}}" alt="img" />
                                    </div>
                                    <div class="text-left ml-3">
                                        <span class="d-block truncate_Name">{{selectWalletName}}</span>
                                        <div class="network text-dark {{ selectWalletCoin.toLowerCase() }}">
                                            {{ selectWalletCoin | getName }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column align-items-center justify-content-center min-height-center">
                        <div class="policy-type-icon-big">
                            <img src="{{selectPolicyIcon}}" alt="img" />
                        </div>
                        <span class="d-block font-weight-bold font-size-16 mt-3">{{policyAmount | number : '1.2-2'}}
                            USD</span>
                        <span class="d-block mt-2">Per transaction</span>
                        <!-- <div *ngIf="ledgerConnect" class="d-flex align-items-center mt-3">
                            <h2 class="mb-0 mr-1 font-size-20">
                                <i class="icon-ledger text-dark"></i>
                            </h2>
                            <div class="ml-2">
                                <span class="d-block">
                                    <a href="javascript:void(0);" class="font-size-13">
                                        Follow the instructions on your Ledger device to Sign
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="!ledgerConnect" class="d-flex align-items-center mt-3">
                            <h2 class="mb-0 mr-1 font-size-20">
                                <i class="icon-trezor text-dark"></i>
                            </h2>
                            <div class="ml-2">
                                <span class="d-block">
                                    <a href="javascript:void(0);" class="font-size-13">
                                        Follow the instructions on your Trezor device to Sign
                                    </a>
                                </span>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="modal-footer border-0" *ngIf="browser == 'Firefox' && deviceName == 'ledger'; else not_fox_c" >
            <div class="text-dark text-center w-100 row py-2">Firefox does not support signing with Ledger. Please use Google Chrome or other supported browser.</div>
        </div> -->
        <!-- <ng-template #not_fox_c> -->
            <div class="modal-footer border-0">
                <button *ngIf="!pendingPolicyData"type="submit" class="btn btn-primary w-100"
                    (click)="createPolicy()">
                    <i *ngIf="!isSigning" class="icon-check mr-1"></i>
                    <div *ngIf="isSigning" class="loader-box">
                        <div class="loader-15 bg-white"></div>
                    </div>
                    <span *ngIf="!isSigning">Approve</span>
                </button>
                <button *ngIf="pendingPolicyData"type="submit" class="btn btn-primary w-100"
                    (click)="signPendingPolicy()">
                    <i *ngIf="!isSigning" class="icon-check mr-1"></i>
                    <div *ngIf="isSigning" class="loader-box">
                        <div class="loader-15 bg-white"></div>
                    </div>
                    <span *ngIf="!isSigning">Approve</span>
                </button>
            </div>
        <!-- </ng-template> -->
    </div>
</ng-template>

<ng-template #successModal let-modal>
    <div class="modal-custom">
        <div class="modal-header border-0">
            <h2 class="modal-title"><i class="icon-shield mr-2"></i> Approve Policy Request</h2>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');"> <i class="icon-close"></i> </button>
        </div>
        <div class="modal-body d-flex align-items-center">
            <div class="row">
                <div class="col-12">
                    <div class="mb-3 position-relative">
                        <div class="background_success text-center"> <img class="w-50" src="../../../assets/images/check-outline-success.gif"> </div>
                    </div>
                    <div class="mb-5 text-center">
                        <div class="h5">Success</div>
                        <div class="text-muted">Awesome! You have successfully initiated this policy request 🎉</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button type="submit" class="btn btn-outline-primary w-100"
                (click)="modal.dismiss('Cross click');"> Done
            </button>
        </div>
    </div>
  </ng-template>

<ng-template #errorModal let-modal>
    <div class="modal-custom">
        <!-- <div class="modal-header border-0"> -->
            <!-- <h2 class="modal-title"><i class="icon-shield mr-2"></i> Failed to add policy</h2> -->
            <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');"> <i class="icon-close"></i> </button> -->
        <!-- </div> -->
        <div class="modal-body d-flex align-items-center  justify-content-center" style="background-color: #fff;">
            <div class="row">
                <div class="col-12">
                    <div class="position-relative text-center mb-4">
                        <img src="../../../assets/images/error.svg"> 
                        
                    </div>
                    <div class="mb-5 text-center">
                        <div class="font-size-20" style="font-weight: 500; margin-bottom: 15px;">Failed to add policy</div>
                        <div class="px-4" style="color: #6F757A;"><span class="">{{errMsg.charAt(0).toUpperCase() + errMsg.slice(1).toLowerCase()}}.</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button type="submit" class="btn btn-outline-primary w-100"
                (click)="modal.dismiss('Cross click');">Close
            </button>
            <div class="w-100 text-center mt-3 mb-2">
                <img src="../../../../assets/images/contact-support.svg" class="mr-2 inverse-icon"/>
                <a class='support_email my_custom_link' href='mailto:support@lmnl.app' supportLink target="_top">Contact Support</a>
            </div>
        </div>
    </div>
  </ng-template>
import { Injectable } from '@angular/core';
import { ErrorPrompt, SuccessPrompt } from '../components/custom-prompt/custom-prompt.service';
import { SuccessPromptPayload } from '../constants/promptMessages';
import { UserRoleEnum } from '../entities/members';
import { AuthServiceJWT } from './auth.service';
import { HttpService } from './http.service';
import { NoShowError, ErrorMsgs } from '../entities/Error';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userDataSubject = new BehaviorSubject<any>(null);
  userData = this.userDataSubject.asObservable();

  constructor(private auth: AuthServiceJWT, private httpService: HttpService) { }

  async updateUserRole(orgId: number, userId: number, userRole: UserRoleEnum): Promise<any> {
    return this.auth.writeAccess('user:role-update').toPromise()
      .then(async (accessToken) => {
        let response: any = await this.httpService.updateOrgUserRole(orgId, userId, userRole, accessToken).toPromise();
        if (response.success === true) {
          SuccessPrompt.fire(SuccessPromptPayload.USER_ROLE_CHANGE);
        } else {
          ErrorPrompt.fire({
            icon: "error",
            title: "Failed to update the user type",
            text: response.message,
            showConfirmButton: false,
          })
        }
        return response;
      })
      .catch((error) => {
        if (error.message === ErrorMsgs.POPUP_CLOSED)
          return Promise.reject(
            new NoShowError("User denied transaction signing", error)
          );
        return Promise.reject({ success: false, message: 'Failed to update the user type' });
      });
  }
  /**
   * Retrieves user data by user ID.
   * @param userId - The ID of the user.
   */
  getUserData(userId: number) {
    this.httpService.getUserById(userId).subscribe((userData) => {
      this.userDataSubject.next(userData);
    });
  }
}



import { Component, Input, OnInit } from '@angular/core';
import { ConstantService } from '../../services/constant.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from '../../services/http.service';
import { MembersService } from '../../services/members.service';
import Swal from 'sweetalert2';
import { SegmentService } from 'ngx-segment-analytics';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {
  getIcon,
  getName,
  getSupportedCoins,
  sortCoinsAlphabetically,
  subName,
  getSupportedCoinGroupText,
} from '../../helpers/coins';
import { environment } from 'src/environments/environment';
import { DataService } from '../../services/data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  MPCTeamConfig,
  MPCWalletData,
  TeamSigningSubType,
  TeamsV2,
  WalletRequest,
} from '../../entities';
import { TeamsService } from '../../services/teams.service';
import { WalletService } from '../../services/wallet.service';
import { MembersType } from './mobile-teams/mobile-teams.component';
import { isCustodyOrg, isSubOrg } from '@helpers/org.utils';
import { ErrorPrompt } from '../custom-prompt/custom-prompt.service';
import { NoShowError } from '../../entities/Error';
import { getParentChain } from '../../helpers/WalletUtils';
import { WALLETS_HARDWARES } from '../../constants/wallet.const';

@Component({
  selector: 'app-new-group-wallet',
  templateUrl: './new-group-wallet.component.html',
  styleUrls: ['./new-group-wallet.component.scss'],
})
export class NewGroupWalletComponent implements OnInit {
  selected_Asset = false;
  step_add_member = false;
  step_link_device = false;
  stepNumber = 1;
  fltr: any;
  _members: any;
  members: any;
  minimalApprovalReq = [1];
  member_Value_drop = 2;
  minApprove_val = 1;
  custom = false;
  selectedItem: any;
  selected = true;
  selectedItem_custom: string;
  shield: boolean = true;
  total_members_limit = false;
  custom_val = false;
  total_members: number;
  min_members: number;
  ttl_member_val: number;
  min_member_val: number;
  enable_preivew = false;
  getmem: any;
  added_member = false;
  type: string;
  final_members: string;
  gas_station: boolean = true;
  user: any;
  selected_asset_value: string;
  walletName: string = '';
  disable_New_Wallet = false;
  get_user_link_details: any;
  verifyWalletName;
  createWalletOption = false;
  createSameAddress: boolean;
  loading: boolean = false;

  signingSubType: TeamSigningSubType;
  checkSignerSelection: boolean = false;
  walletType = [
    {
      name: 'Mobile (MPC)',
      value: 'mpc',
    },
    {
      name: 'MULTI SIG',
      value: 'multisig_shield',
    },
  ];
  selected_type: any = {};
  notLinkedMemberSigner = [];
  notLinkedMemberInitiator = [];
  isChainLinkeds: any = true;
  rangeNode = [
    {
      key: 2,
    },
    {
      key: 3,
    },
    {
      key: 4,
    },
    {
      key: 5,
    },
    {
      key: 6,
    },
    {
      key: 7,
    },
    {
      key: 8,
    },
    {
      key: 9,
    },
    {
      key: 10,
    },
  ];

  @Input() component_for: string;
  link_component_for = 'link_new';
  dropdownSettings: IDropdownSettings = {};
  coin: any;
  teamsListData: TeamsV2[];
  teamsList: TeamsV2[];
  onDestroy$: Subject<void> = new Subject();
  orgProfile: any = {};

  TeamSigningSubType = TeamSigningSubType;

  // Specific to the MPC flow
  MembersType = MembersType;
  mpcCreateStep: number;
  mpcWalletData: MPCWalletData;

  userKeys: any;
  currentUserDetails: any;
  isCommonSignersInitiators: boolean = false;
  disableCommonSignersInitiators: boolean = false;
  displayFirewallWarning: boolean = false;
  commonSigners = [];
  constructor(
    private org: ConstantService,
    private data: DataService,
    private modalService: NgbModal,
    private httpservice: HttpService,
    private memberservice: MembersService,
    private segment: SegmentService,
    config: NgbModalConfig,
    private teamsService: TeamsService,
    private walletService: WalletService
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.total_members = 2;
    this.min_members = 1;
    this.user = JSON.parse(localStorage.getItem('user'));
    data.getUserProfile
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((user_detail) => {
        this.currentUserDetails = user_detail;
        this.org.changeOrg(user_detail['organizations']);
        this.orgProfile = user_detail['organizations'][0];
        if (user_detail['organizations'][0].type === 2) {
          let data = user_detail;
          if (isSubOrg(user_detail['organizations'][0])) {
            // Get the keys that exist in obj2.MPC_Protocol_Support
            const validKeys = Object.keys(environment.MPC_Protocol_Support);

            // Remove the keys in obj1.keys that are not in validKeys
            for (const key of Object.keys(data.keys)) {
              if (!validKeys.includes(key)) {
                delete data.keys[key];
              }
            }
            this.fltr = data;
          } else {
            this.fltr = data;
          }
          this.sortKeysAlphabetically();
          this.disable_New_Wallet = true;
        }
        if (environment.disableTennetBuildFts) {
          this.disable_New_Wallet = false;
        }
        // this.fltr = user_detail;
        this.get_user_link_details = user_detail['keys'];
      });

    httpservice
      .getAllMembers()
      .pipe()
      .subscribe((allmember) => {
        this.members = allmember[0].members.filter((e) => e.userType == 1);
      });
    this.getTeamList();
  }

  ngOndestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async getProfile() {
    this.userKeys = await this.httpservice.getprofile().toPromise().then();
  }

  isChainLinked(chain): boolean {
    let chainDevices = this.userKeys?.keys[`${chain}`];
    for (const item of chainDevices) {
      if (item.provider === 'trezor' || item.provider === 'ledger') {
        return true;
      }
    }
    return false;
  }

  getTeamList() {
    this.teamsService.getAllTeams().subscribe((allTeams) => {
      this.teamsList = allTeams.teamListData;
      this.teamsListData = allTeams.teamListData;
    });
  }

  async getMember(orgId) {
    let _members = await this.httpservice.getOrgMembers(orgId);
    console.info('org id are --------', orgId, _members.members);
    // this.members = _members.member
  }

  ngOnInit() {
    this.stepNumber = 1;
    this.getProfile();
    this.getmem = [];
    this.memberservice.resetMember();
    this.members = [];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'displayName',
      enableCheckAll: false,
      allowSearchFilter: true,
      limitSelection: this.total_members,
      noDataAvailablePlaceholderText: '',
    };
  }

  getName = (id) => {
    return getName(id);
  };

  getSubName = (id) => {
    return subName(id);
  };

  getSupportedCoins = (key) => {
    return getSupportedCoins(key);
  };

  getSupportedCoinGroupText = (key) => {
    return getSupportedCoinGroupText(key);
  };

  async check_members() {
    let user_detail = this.data.getUserProfile.getValue();
    this.get_user_link_details = user_detail['keys'];

    var rtn_linked_device = false;
    Object.values(this.get_user_link_details).forEach(function (value) {
      if (Object.keys(value).length > 0) {
        rtn_linked_device = false;
      }
    });

    var chk_link_device: Boolean;
    if (rtn_linked_device) {
      chk_link_device = rtn_linked_device;
    } else {
      chk_link_device = rtn_linked_device;
    }
    if (this.members && chk_link_device) {
      this.step_add_member = false;
      this.stepNumber = 1;
    } else {
      if (chk_link_device) {
        this.step_add_member = true;
        this.stepNumber = 0;
      } else {
        this.step_link_device = false;
        this.stepNumber = 1;
      }
    }
  }

  select_Asset(asset, t) {
    this.walletType = [
      {
        name: 'Mobile (MPC)',
        value: 'mpc',
      },
      {
        name: 'MULTI SIG',
        value: 'multisig_shield',
      },
    ];
    if (getSupportedCoins(asset)?.length > 0) {
      this.createWalletOption = true;
    } else {
      this.createWalletOption = false;
    }

    this.selected_Asset = true;
    this.selected_asset_value = asset;

    if (isSubOrg(this.orgProfile)) {
      this.signingSubType = TeamSigningSubType.MPC;
    } else {
      if (environment.MPC_Protocol_Support[asset]?.signerType.includes('MPC')) {
        this.signingSubType = TeamSigningSubType.MPC;
      } else this.signingSubType = TeamSigningSubType.MULTISIG;
    }

    this.members.map(
      (member) => (member.isDisabled = !member.keys[this.selected_asset_value])
    );
    this._members = this.members;
  }

  change_Asset() {
    this.selected_Asset = false;
    this.selected_asset_value = '';
    this.coin = '';
    this.selectedItem = null;
    this.selected = true;
    this.custom = false;
    this.selectedItem_custom = '';
    this.createWalletOption = false;
    this.selected_type.name = '';
    this.signingSubType = null;
  }

  onNextBtnClick() {
    if (this.stepNumber === 1 && this.isMPC) {
      this.mpcCreateStep = MembersType.INITIATORS;
    } else if (
      this.stepNumber === 2 &&
      this.isMPC &&
      this.mpcCreateStep !== MembersType.SIGNERS &&
      !this.isCustodyOrg()
    ) {
      this.mpcCreateStep = MembersType.SIGNERS;
      return;
    }
    this.stepNumber = this.stepNumber + 1;
    if (this.stepNumber === 3) {
      this.getMpcCommonMembers();
      if(this.orgProfile?.isFirewallEnabled) {
        this.displayFirewallWarning = this.signingSubType === TeamSigningSubType.MULTISIG && !this.isChainSupported(this.selected_asset_value);
      }
    }
  }

  onBackButtonClick() {
    if (this.stepNumber === 2 && this.mpcCreateStep === MembersType.SIGNERS) {
      this.mpcCreateStep = MembersType.INITIATORS;
      return;
    } else if (
      this.stepNumber === 2 &&
      this.isMPC &&
      this.mpcCreateStep === MembersType.INITIATORS
    ) {
      // Reset data
      this.mpcWalletData = new MPCWalletData();
    }

    this.stepNumber--;
  }

  closeModal() {
    this.minimalApprovalReq = [1];
    this.member_Value_drop = 2;
    this.minApprove_val = 1;
    this.stepNumber = 1;
    this.members = [];
    this.mpcWalletData = null;
    this.signingSubType = null;
    this.selected_type = {};
    this.resetSendModel();
    this.memberservice.resetMember();
    this.modalService.dismissAll();
    this.notLinkedMemberSigner = [];
    this.notLinkedMemberInitiator = [];
  }

  async create_Wallet_version2() {
    const walletRequest = new WalletRequest(
      this.walletName,
      'mpc',
      this.selected_asset_value,
      this.mpcWalletData.signers?.team?.id,
      this.mpcWalletData.initiators?.team?.id,
      this.mpcWalletData.initiators?.minMembersRequired,
      this.mpcWalletData.signers?.minMembersRequired
    );

    walletRequest.allowInitiatorToSign = this.isCommonSignersInitiators;
    try {
      let wallet_data;

      if (isCustodyOrg(this.orgProfile)) {
        wallet_data = await this.walletService
          .createWalletWith2FA(walletRequest, 'CUSTODY')
          .toPromise();
      } else {
        wallet_data = await this.walletService
          .createWalletWith2FA(walletRequest, 'NONCUSTODY')
          .toPromise();
      }
      this.segment.track('team-added-wallet', walletRequest).catch((err) => {});
      this.onNextBtnClick();
      this.members = [];
      this.memberservice.resetMember();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.modalService.dismissAll();
      if (err instanceof NoShowError) {
        return;
      }
      ErrorPrompt.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${err?.error?.message || err?.message}`,
      });
    }
  }

  open(content) {
    this.getTeamList();
    this.minimalApprovalReq = [1];
    this.member_Value_drop = 2;
    this.minApprove_val = 1;
    this.selected_type = {};
    this.notLinkedMemberInitiator = [];
    this.notLinkedMemberSigner = [];
    this.createSameAddress = false;
    this.coin = '';
    this.check_members();

    this.signingSubType = null;
    this.mpcWalletData = new MPCWalletData();

    this.modalService
      .open(content, {
        windowClass: 'new-group-wallet modal-custom-background',
        centered: true,
      })
      .result.then(
        (result) => {
          this.resetSendModel();
          // this.resetAmmountValidationErrormessages();
        },
        (reason) => {
          this.resetSendModel();
          // this.resetAmmountValidationErrormessages();
        }
      );
  }

  resetSendModel() {
    this.notLinkedMemberInitiator = [];
    this.notLinkedMemberSigner = [];
    this.custom = false;
    this.selected = true;
    this.set_members('1-2');
    this.shield = true;
    this.total_members_limit = false;
    this.stepNumber = 1;
    this.walletName = '';
    this.selected_Asset = false;
    this.memberservice.resetMember();
    this.httpservice
      .getAllMembers()
      .pipe()
      .subscribe((allmember) => {
        allmember.filter((t) => {
          this.members = t.members.filter((x) => {
            x.email === this.user.email;
            return x;
          });
          this.members = this.members.filter((e) => e.userType == 1);
        });
      });
  }

  onDropDownBtnClick(data, type, d) {
    if (type == 'wallet_type') {
      this.selected_type = data;
      this.selected_type.name = data.name;
      this.teamsListData = this.teamsList.filter(
        (ele) => ele.type === this.selected_type.value
      );
    }
  }

  isTeamMemberLinkDeviceSigner(data, type) {
    this.notLinkedMemberInitiator = [];
    this.notLinkedMemberSigner = [];
    let d = data?.map((element) => {
      let findMember = this.members?.find((ele) => ele.id === element.userId);
      if (findMember) {
        let findMobileSigner = findMember?.providers?.find(
          (mem) => mem === 'mobile_mpc'
        );
        if (findMobileSigner) {
          element.providersSigner = true;
        } else {
          element.providersSigner = false;
        }
      }
      return element;
    });
    if (type === 'initiator') {
      this.notLinkedMemberInitiator = d?.filter((ele) => ele.member === false);
    } else {
      this.notLinkedMemberSigner = d?.filter(
        (ele) => ele.providersSigner === false
      );
    }
    return d;
  }

  custom_range() {
    this.stepNumber = 0;
    this.custom = true;
    this.custom_val = true;
    this.total_members = this.member_Value_drop;
    this.min_members = this.minApprove_val;
    this.min_member_val = this.min_members;
    this.ttl_member_val = this.total_members;
  }

  set_members(newValue) {
    this.total_members = parseInt(newValue.split(['-'])[1]);
    this.dropdownSettings.limitSelection = this.total_members;
    this.min_members = newValue.split(['-'])[0];
    this.selectedItem = newValue; // don't forget to update the model here
    this.selected = false;
    this.selectedItem_custom = '';
    this.custom_val = false;
    this.memberservice.resetMember();
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, {
      limitSelection: this.total_members,
    });
    this.httpservice
      .getAllMembers()
      .pipe()
      .subscribe((allmember) => {
        allmember.filter((t) => {
          this.members = t.members.filter((x) => {
            x.email === this.user.email;
            return x;
          });
          this.members = this.members.filter((e) => e.userType == 1);
        });
      });
    this.total_members_limit = false;
    this.enable_preivew = false;
  }

  get getMembers() {
    this.members.map((member) => {
      let isHardwareLinkedToWallet = false;

      if (this.signingSubType === TeamSigningSubType.MULTISIG) {
        member?.providers &&
          member.providers.map((item) => {
            if (WALLETS_HARDWARES.includes(item)) {
              isHardwareLinkedToWallet = true;
            }
          });
      }

      member.isDisabled =
        !member.keys[this.selected_asset_value] || !isHardwareLinkedToWallet;
    });
    return this.members.reduce((acc, member) => {
      acc[member.id] = member;
      return acc;
    }, {});
  }

  onMemberSelect(memberOption: any) {
    const member = this.getMembers[memberOption.id];
    if (!member.isDisabled) {
      if (this.getmem) {
        if (this.getmem.length < this.total_members) {
          this.added_member = false;
          this.getmem.forEach((key) => {
            if (key.email == member.email) {
              this.added_member = true;
            }
          });
          if (this.added_member == false) {
            this.memberservice.addMember(
              member.displayName,
              member.phptoURL,
              member.email,
              member.userid
            );
            this.getmem = this.memberservice.getMember();
          }
        } else {
          this.total_members_limit = true;
        }
      } else {
        this.memberservice.addMember(
          member.displayName,
          member.phptoURL,
          member.email,
          member.userid
        );
        this.getmem = this.memberservice.getMember();
      }
      if (this.getmem.length == this.total_members) {
        this.enable_preivew = true;
      }
    } else {
      return false;
    }
  }

  onMemberDeSelect(memberOption: any) {
    this.getmem = this.memberservice.removeMember(memberOption);
    this.getmem = this.memberservice.getMember();
    if (this.getmem.length == this.total_members) {
      this.enable_preivew = true;
    } else {
      this.enable_preivew = false;
    }
  }

  custom_members() {
    this.set_members(`${this.minApprove_val}-${this.member_Value_drop}`);
    this.stepNumber = 1;
    this.custom = false;
    this.selectedItem_custom = 'custom';
    this.selectedItem = false;
    this.selected = false;
    this.memberservice.resetMember();
    this.httpservice
      .getAllMembers()
      .pipe()
      .subscribe((allmember) => {
        allmember.filter((t) => {
          this.members = t.members.filter((x) => {
            x.email === this.user.email;
            return x;
          });
          this.members = this.members.filter((e) => e.userType == 1);
        });
      });
    this.total_members_limit = false;
    this.enable_preivew = false;
  }

  selectMember(totalMember) {
    this.member_Value_drop = totalMember.key;
    this.total_members = totalMember.key;
    if (totalMember.key < this.minApprove_val) {
      this.minApprove_val = totalMember.key - 1;
      this.min_members = totalMember.key - 1;
    }

    this.minimalApprovalReq = [];
    for (let x = 1; x < totalMember.key; x++) {
      this.minimalApprovalReq.push(x);
    }
  }

  onItemSelect(item) {
    this.minimalApprovalReq = [];
    for (let x = 1; x < item.key; x++) {
      this.minimalApprovalReq.push(x);
    }
  }

  min_app_member(item) {
    this.min_members = item;
    this.minApprove_val = item;
  }

  numberList() {
    let finalRange = this.rangeNode;
    if (this.selected_asset_value.toUpperCase() == 'TRON')
      return finalRange.slice(0, 4);
    else return this.rangeNode;
  }

  async create_Wallet_version1(
    name,
    asset,
    m,
    group_members,
    shield,
    gas_station,
    members_list
  ) {
    if (shield) {
      this.type = 'multisig_shield';
    } else {
      this.type = 'multisig';
    }
    let cosignerids: string[] = [];
    members_list.forEach((element) => cosignerids.push(element.email + ''));
    try {
      let wallet_data = await this.walletService
        .createNewMultisigWalletWith2FA(name, m, cosignerids, this.type, asset)
        .toPromise();
      const segmentData = {
        userData: this.currentUserDetails,
        walletType: this.type,
        protocol: asset,
        name: this.walletName,
        asset_details: {
          signerType: this.type,
          signers: cosignerids,
        },
      };
      this.segment
        .track('wallet-create-success', segmentData)
        .catch((err) => {});
      this.onNextBtnClick();
      this.members = [];
      this.memberservice.resetMember();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.modalService.dismissAll();
      if (err instanceof NoShowError) {
        return;
      }
      ErrorPrompt.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${err?.error?.message || err.message}`,
      });
    }
  }

  confirm_wallet(content) {
    let configCount = 0;
    if (this.isMPC && this.mpcWalletData?.initiators?.minMembersRequired) {
      configCount = this.mpcWalletData?.initiators?.minMembersRequired;
    } else if (this.isMultisig && this.min_members) {
      configCount = this.min_members;
    }
    if (this.isCommonSignersInitiators && this.commonSigners?.length && configCount < 2) {
      this.modalService
      .open(content, {
        windowClass: "new-group-wallet modal-custom-background",
        centered: true,
      })
      .result.then(
        (result) => {},
        (reason) => {
          if (reason) {
            this.confirmWalletHandler();
          } else {
            const initiatorMembers = this.mpcWalletData.initiators?.team?.teamMembers;
            this.handleCommonSignersFlag(initiatorMembers);
          }
        });
      return;
    } else {
      this.confirmWalletHandler();  
    }
    
  }

  confirmWalletHandler() {
    this.loading = true;
    if (this.isMPC) {
      this.create_Wallet_version2();
    } else {
      this.final_members = this.min_members + 'of' + this.total_members;
      try {
        this.create_Wallet_version1(
          this.walletName,
          this.selected_asset_value,
          this.min_members,
          this.final_members,
          this.shield,
          this.gas_station,
          this.getmem
        );
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    }
    this.loading = false;
  }

  get isMultisig(): boolean {
    return this.signingSubType === TeamSigningSubType.MULTISIG;
  }

  get isMPC(): boolean {
    return this.signingSubType === TeamSigningSubType.MPC;
  }

  onInitiatorsSelect(initiators: MPCTeamConfig) {
    this.mpcWalletData.initiators = initiators;
  }

  onSignersSelect(signers: MPCTeamConfig) {
    this.mpcWalletData.signers = signers;
  }

  get isValidInitiators(): boolean {
    return this.mpcWalletData.initiators?.isValidTeam;
  }

  get isValidSigners(): boolean {
    return this.mpcWalletData.signers?.isValidTeam;
  }

  getMpcCommonMembers() {
    const initiatorMembers = this.mpcWalletData.initiators?.team?.teamMembers;
    const signerMembers = this.mpcWalletData.signers?.team?.teamMembers;
    if (initiatorMembers && signerMembers) {
      const initiatorIds = new Set(initiatorMembers.map(member => member.user?.id));
      this.commonSigners = signerMembers.filter(signerMember => initiatorIds.has(signerMember.user?.id));
      this.handleCommonSignersFlag(initiatorMembers);
    }
    return [];
  }

  isValidAsset(): boolean {
    let key = Object.keys(this.fltr.keys);
    if (key.includes(this.selected_asset_value)) {
      return true;
    }
    return false;
  }

  get disableMultiSigFlow() {
    if (!this.isValidAsset) return true;
    return (
      isCustodyOrg(this.orgProfile) ||
      !environment.MPC_Protocol_Support[
        this.selected_asset_value
      ]?.signerType.includes('MULTI SIG')
    );
  }

  get disableMPCFlow() {
    if (!this.isValidAsset) return true;
    return !environment.MPC_Protocol_Support[
      this.selected_asset_value
    ]?.signerType.includes('MPC');
  }

  isCustodyOrg(): boolean {
    return isCustodyOrg(this.orgProfile);
  }

  sortKeysAlphabetically() {
    this.fltr.keys = sortCoinsAlphabetically(this.fltr.keys);
  }

  returnZero() {
    return 0;
  }

  isChainSupported(chain): boolean {
    if (
      getParentChain(chain) == 'EVM' ||
      getParentChain(chain) == 'UTXO' ||
      getParentChain(chain) == 'TRON'
    ) {
      return true;
    }
    return false;
  }

  getUserNamesString() {
    return this.commonSigners.map((e) => e.user.displayName).join(', ');
  }

  handleCommonSignersFlag(initiatorMembers: unknown[]) {
    if (initiatorMembers.length === this.commonSigners.length) {
      this.isCommonSignersInitiators = true;
      this.disableCommonSignersInitiators = true;
    } else {
      this.isCommonSignersInitiators = false;
      this.disableCommonSignersInitiators = false;
    }
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthServiceJWT } from './shared/services/auth.service';
import { HttpService } from '../app/shared/services/http.service';
import { setUserProfile, updateUserDisplayName } from './shared/helpers/user.utils';
import { SegmentService } from 'ngx-segment-analytics';
import { RiskDisclosure } from '@entities/User';
import * as OrgUtils from './shared/helpers/org.utils';
import { AccessControlService } from "./shared/services/access-control.service"
import { OrganizationService } from './shared/services/organization.service';
import { DataService } from './shared/services/data.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
  constructor(private segment: SegmentService, private router: Router, private authService: AuthServiceJWT, private httpService: HttpService,
    private accessControlService: AccessControlService, private orgSvc: OrganizationService, private dataService: DataService) {
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    if (!this.authService.isLoggedIn) {
      setTimeout(() => {
        this.router.navigateByUrl('/login');
        return false;
      }, 3000);
      
    } else {
      const user = this.authService.getUser;
      
      if (!user.emailVerified) {
        if (window.location.pathname === '/verify-email') {
          // return true;
        } else {
          window.location.href = "/verify-email";
        }
        return true;
      }

      const currentOrg = this.orgSvc.getCurrentOrg();

      if(!currentOrg) {
        this.router.navigateByUrl('/choose-organization');
      }
      this.dataService.loadData();

      const profile = await this.httpService.getprofile().toPromise();
      const storageUserExists = Boolean(localStorage.getItem('userProfile') && JSON.parse(localStorage.getItem('userProfile')).userId !== "");
      localStorage.setItem("orgId", profile?.organizations[0]?.id || null);
      if (!storageUserExists) {
        setUserProfile(profile, user);
      }

      const organizations = profile['organizations'];

      if (organizations.length > 0) {
        // Set the custody org flag
        const isCustodyOrg = OrgUtils.isCustodyOrg(organizations[0]);
        this.accessControlService.setIsCustodyOrg(isCustodyOrg);

        sessionStorage.setItem(
          'rd_popup',
          ((profile.risk_disclosure == null || profile.risk_disclosure == RiskDisclosure.UNDERSTAND) && organizations[0]?.risk_disclosure) ? 
          'true' : 
          'false');
        if (window.location.pathname === '/verify-email') {
          this.router.navigate(['/']);
        }
        return true;
      } else {
        let userPendingOrgReq = await this.httpService.getAllPendingUserReq().toPromise();
        if (!userPendingOrgReq.length) {
          this.router.navigate(['/restricted-access']);
          return true;
        }

        let { success, data, message } = await this.httpService.acceptDeclineOrg(userPendingOrgReq[0].request.id, "1").toPromise();
        if (success == true) {
          this.segment.track("signup-success", userPendingOrgReq[0]).catch((err) => { });
          this.segment.track("invitation-accepted", userPendingOrgReq[0]).catch((err) => { });
          // Update user display name
          if (data) updateUserDisplayName(data.displayName);

          if (window.location.pathname === '/verify-email') {
            this.router.navigate(['/']);
          }
          return true;
        } else {
          alert(message);
        }
      }
    }
  }
}
import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import { Observable} from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor( private httpClient: HttpClient ) {}

  baseUrl: string = environment.apiUrl

  /**
   * Retrieves wallet addresses based on the provided parameters.
   * 
   * @param walletId - The ID of the wallet.
   * @param assetType - The type of asset.
   * @param pageNumber - The page number.
   * @param pageSize - The number of addresses per page.
   * @returns An Observable that emits the retrieved wallet addresses.
   */
  getWalletAddresses(
    walletId: Number, 
    assetType: string, 
    pageNumber: number, 
    pageSize: number
  ): Observable<any> {

    return this.httpClient
          .get(`${this.baseUrl}/v2/wallets/${walletId}/addresses?assetType=${assetType}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
  }

  /**
   * Searches for address details by wallet ID and address.
   * 
   * @param walletId - The ID of the wallet.
   * @param address - The address to search for.
   * @param pageNumber - The page number for pagination.
   * @param pageSize - The number of items per page.
   * @returns An Observable that emits the address details.
   */
  searchAddressDetailsByWalletIdAndAddress(
    walletId: Number, 
    address: string,
    pageNumber: number, 
    pageSize: number 
  ): Observable<any> {
    
    return this.httpClient
          .get(`${this.baseUrl}/v2/wallets/${walletId}/addresses/${address}?pageNumber=${pageNumber}&pageSize=${pageSize}`);
  }
}

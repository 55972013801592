import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-reject-invitation-popup',
  templateUrl: './reject-invitation-popup.component.html',
  styleUrls: ['./reject-invitation-popup.component.scss']
})
export class RejectInvitationPopupComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  rejectInvite(result: boolean){
    this.activeModal.close({result: result});
  }

}

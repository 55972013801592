import { Injectable, OnInit, NgZone } from "@angular/core";
import { ConstantService } from "./constant.service";
import { Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { CookieService } from "ngx-cookie-service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { SegmentService } from "ngx-segment-analytics";
import { map, switchMap } from "rxjs/operators";
import {
  getUserProfile,
  setUserProfile,
} from "../../shared/helpers/user.utils";
import { IAuthUserType } from '../entities/User';
import { EventService } from "./events";
import {
  OrganizationService,
  PendingOrganization,
} from "./organization.service";

@Injectable({
  providedIn: "root",
})
export class AuthServiceJWT implements OnInit {
  public userData: any;
  public showLoader: boolean = false;
  userId: any;
  created_at: any;
  baseUrl: string = environment.apiUrl;
  constructor(
    public afAuth: AuthService,
    public router: Router,
    public ngZone: NgZone,
    private cookieService: CookieService,
    private httpClient: HttpClient,
    private segment: SegmentService,
    private org: ConstantService,
    private eventService: EventService,
    private orgSvc: OrganizationService
  ) {
    // this.afAuth.isAuthenticated$.subscribe(isAuthenticated => {
    //   // if (!isAuthenticated) {console.log("isauth", isAuthenticated)};
    // });

    // this.afAuth.idTokenClaims$.subscribe(idToken => {
    //   // if(idToken)
    //   localStorage.setItem('idToken', JSON.stringify(idToken));
    // });

    this.afAuth.user$.subscribe(async (user) => {
      if (user) {
        let token = await this.afAuth.getIdTokenClaims().toPromise();
        localStorage.setItem("idToken", JSON.stringify(token));

        this.userData = {
          email: user.email,
          displayName: user.name,
          uid: user.email,
          photoURL: user.picture || "assets/dashboeard/boy-2.png",
          emailVerified: user.email_verified,
          sub: user.sub,
          iss: token.iss,
          id: user.id,
        };
        cookieService.set("user", JSON.stringify(this.userData));
        localStorage.setItem("user", JSON.stringify(this.userData));
        const storageData = localStorage.getItem("userProfile");
        if (!storageData) {
          setUserProfile(this.userData, user as unknown as IAuthUserType);
        }
        if (this.router.url === "/login") {
          const orgList: PendingOrganization[] = await this.orgSvc
            .fetchOrgList()
            .toPromise();
          this.ngZone.run(() => {
            if (!orgList.length) {
              return this.orgSvc.navigateToRestrictedPage();
            }
            if (orgList && orgList.length === 1) {
              const org: PendingOrganization = orgList[0];
              if (!org.isInvited) {
                this.orgSvc.setCurrentOrganization(org);
                this.router.navigate(["/"]);
              } else {
                this.router.navigate(["/choose-organization"]);
              }
            } else {
              this.router.navigate(["/choose-organization"]);
            }
          });
        }
      } else {
        localStorage.setItem("idToken", "");
        localStorage.setItem("user", "");
      }
    });
  }

  ngOnInit(): void {}

  //Sign in with Facebook
  signInFacebok() {
    return this.afAuth.loginWithRedirect();
  }

  //Sign in with Twitter
  signInTwitter() {
    return this.afAuth.loginWithPopup();
  }

  //Sign in with Google
  GoogleAuth() {
    return this.afAuth.loginWithRedirect();
  }

  async loginWithRedirect(action: string, org?: string) {
    return this.afAuth.loginWithRedirect({
      action: action,
      orgName: org,
      support_url: environment.auth0SupportUrl,
      buildFor: environment.buildFor,
    });
  }

  SetUserData(user) {
    const userData = {
      email: user.email,
      displayName: user.name,
      uid: user.email,
      photoURL: user.picture || "assets/dashboeard/boy-2.png",
      emailVerified: user.email_verified,
    };
  }

  existingMessageSignTxn() {
    return localStorage.getItem("walletConnectMpcTxn");
  }

  //Authentication for Login
  AuthLogin() {
    return this.afAuth.loginWithPopup();
  }
  // Sign out
  SignOut() {
    const user_obj = this.getUser;
    this.showLoader = false;
    this.eventService.postEvent("logout-success");
    localStorage.clear();
    this.cookieService.deleteAll("user", "/auth/login");
    this.afAuth.logout({ returnTo: document.location.origin });
    this.segment
      .track("logout-success")
      .then(() => console.log("Logout success"))
      .catch((err) => {});
    localStorage.removeItem("rd_open");
  }

  get isLoggedIn(): boolean {
    try {
      const userData = localStorage.getItem("user");
      if (userData) {
        const user = JSON.parse(userData);
        // return (user != null && user.emailVerified != false) ? true : false;
        return !!user;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  get getUser(): any {
    try {
      let user = JSON.parse(localStorage.getItem("user"));
      const userProfile = getUserProfile();

      if (userProfile) {
        return { ...user, displayName: userProfile.name };
      }

      return user != null ? user : false;
    } catch (error) {
      console.log(error);
    }
  }

  get getToken(): string {
    try {
      const idToken = JSON.parse(localStorage.getItem("idToken"));
      return idToken.__raw;
    } catch (error) {
      console.log(error);
    }
  }

  get idTokenExpiry(): number {
    try {
      const idToken = JSON.parse(localStorage.getItem("idToken"));
      return idToken.exp * 1000;
    } catch (error) {
      console.log(error);
    }
  }

  get userProfile(): string {
    try {
      const userProfile = JSON.parse(localStorage.getItem("userProfile"));
      return userProfile.__raw;
    } catch (error) {
      console.log(error);
    }
  }

  public writeAccess(scopeType: string) {
    return this.afAuth.loginWithPopup({ scope: scopeType }).pipe(
      switchMap((data) => this.afAuth.getIdTokenClaims({ scope: scopeType })),
      map((accessToken) => {
        return accessToken.__raw;
      })
    );
  }

  public getprofile(user) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Accept", "application/json");
    headers = headers.append("Authorization", "Bearer " + this.getToken);
    const promise = this.httpClient
      .get(this.baseUrl + "/account/me", { headers: headers })
      .toPromise();
    promise.then(
      (userInfo) => {
        // console.log("Promise resolved with: " + JSON.stringify(userInfo));
        this.org.changeOrg(userInfo["organizations"]);
        this.created_at = userInfo["created_at"];
        this.userId = userInfo["id"];
        const traits = setUserProfile(userInfo, user);
        let userId = this.userId;
        this.segment
          .identify(userId, traits)
          .then(() => console.info("Login Identify Event sended"))
          .catch((err) => {});
        this.segment
          .track("login-success")
          .then(() => console.info("Login success Event sended"))
          .catch((err) => {});
        this.eventService.postEvent("login-success");
      },
      (error) => {
        console.error("Promise rejected with " + JSON.stringify(error));
        throw Error(error);
        // this.logger.error(error, error);
        // if(error.error.message == "Organization is Inactive"){
        //   this.router.navigate(['/access-denied'],{ queryParams: {errorMsg:error.error.message}});

        // }
      }
    );
    return promise;
  }

  /**
   * Triggers the login success event.
   *
   * This method checks if the login success event should be triggered and performs the following actions:
   * - Triggers the Segment tracking event for login success.
   * - Posts the login success event.
   * - Sets the localStorage flag to indicate that the event has been triggered.
   */
  public triggerLoginSuccessEvent(): void {
    const loginSuccessEventKey = "loginSuccessEventTriggered";

    // Check if the login success event has already been triggered
    if (localStorage.getItem(loginSuccessEventKey) === "true") {
      return;
    }

    // Trigger the Segment tracking event
    this.segment
      ?.track("login-success")
      .then(() => console.info("Login success event sent"))
      .catch((err) => {
        console.error("Failed to send login success event:", err);
      });

    // Post the login success event
    this.eventService?.postEvent("login-success");

    // Set the localStorage flag to indicate the event has been triggered
    localStorage.setItem(loginSuccessEventKey, "true");
  }
}

import { cosmosSignature, error, Output, PreBuildTx, SignedTransaction, TransactionRequest, TxJson, Wallet } from '../../../entities';
import Swal from 'sweetalert2';
import { Guid } from "guid-typescript";
import TransportWebUSB from '@ledgerhq/hw-transport-webusb';
import { LedgerService } from '../../ledger/ledger';
import { makeCosmoshubPath } from '@cosmjs/amino';
import { LedgerSigner } from '@cosmjs/ledger-amino';
import { environment } from '../../../../../environments/environment';
import { SignerData, SigningStargateClient } from '@cosmjs/stargate';
import { toBase64 } from '@cosmjs/encoding';
import { ErrorPrompt } from 'src/app/shared/components/custom-prompt/custom-prompt.service';
import { HttpErrorResponse } from '@angular/common/http';

export async function signCosmos(tx_obj: TxJson, wallet_obj: Wallet): Promise<cosmosSignature | error[]> {
    // this.chainName = tx_obj.chainId;
    let CoinName = 'Cosmos';
    // let transport = await TransportWebHID.create();

    // Prepare ledger
    const ledgerTransport = await TransportWebUSB.create(120_000, 120_000);
    let currentapp = await LedgerService.getCurrentApp(ledgerTransport);
    console.log('currentapp', currentapp);
    if (currentapp.name != CoinName) {
        return [{ "error": "please Open Cosmos App on Ledger and Try Again" }];
    }
    // await LedgerService.openApp(CoinName,ledgerTransport)
    // Setup signer
    const offlineSigner = new LedgerSigner(ledgerTransport, {
        hdPaths: [makeCosmoshubPath(0), makeCosmoshubPath(1), makeCosmoshubPath(10)],
        testModeAllowed: true,
        prefix: environment.cosmos_config[wallet_obj.chain.toUpperCase()].prefix,
    });

    const signerAddress = (await offlineSigner.getAccounts())[0]?.address

    const signingClient = await SigningStargateClient.offline(offlineSigner);
    if (!(tx_obj.accountNumber != undefined) || !(tx_obj.sequence != undefined)) return

    const signerData: SignerData = {
        accountNumber: tx_obj.accountNumber,
        sequence: tx_obj.sequence,
        chainId: tx_obj.chainId,
    };

    const { bodyBytes, signatures } = await signingClient.sign(
        signerAddress,
        tx_obj.msgs,
        tx_obj.fee,
        tx_obj.memo,
        signerData,
    );
    console.info('sign 00', toBase64(bodyBytes));
    console.info('sign 01', toBase64(signatures[0]));

    let data: cosmosSignature = {
        bodyBytes: toBase64(bodyBytes),
        signatures: toBase64(signatures[0])
    };
    await ledgerTransport.close();
    console.info('data', data);

    return data;

}

export async function signCosmosTransaction(output: Output, req: TransactionRequest, type: string) {
    console.log('cosmos sign');
    console.log(output);
    console.log(req);
    console.log(type);

    try {
        let txParam: PreBuildTx;
        // var txJSON;
        var txid;
        // let CoinName = "Tron";
        if (req && 'raw' in req) {
            // approval code
            let objwalletkey = this.wallet.walletKeys.filter(item => item.ismine === true);
            txid = req.id;
            let txRaw = JSON.parse(req.raw);
            txParam = txRaw.data
            console.info(typeof (txParam));
            console.info(txParam);

            // txJSON = JSON.stringify(txParam)
            // console.log(txJSON);

            // console.log('payload',JSON.parse(txParam.halfSigned.payload));
            // var JSONPayload = JSON.parse(txParam.halfSigned.payload);
            // var jsnpayloaddata = typeof JSONPayload.data === 'object' && JSONPayload.data !== null ? JSONPayload.data : JSON.parse(JSONPayload.data);
            // var raw_tx = jsnpayloaddata.raw_data_hex;
            // let transport = await TransportWebHID.create();
            // const trx = new Trx(transport);
            // const signature = await trx.signTransaction("44'/195'/0'/0/0", raw_tx, []);
            // await transport.close();
            const signData: cosmosSignature | error[] = await signCosmos(txParam.txJson, this.wallet);
            if (signData[0]?.error) {
                return signData;
            }
            var data = {
                "sig": signData['signatures'],
                "pubkey": objwalletkey[0].xpub
            }
            console.info('data approve', data);

            var respApprove = await this.httpService.ApproveTx(JSON.stringify(data), this.wallet.id, txid);
            console.log(respApprove)
            if (respApprove.status !== 200) {
                return [{ "error": respApprove }]
            }
            return "success";
        }
        else {
            // new transaction flow
            var respData: PreBuildTx = await this.httpService.getPrebuildTx({
                reqobj:
                {
                    nonce: 0,
                    output: output,
                    wallet: this.wallet,
                    type: type.toLowerCase(),
                }
            })
            console.log('respData', respData);
            const signData: cosmosSignature | error[] = await signCosmos(respData.txJson, this.wallet);
            if (signData[0]?.error) {
                return signData;
            }
            console.info('dhruv sign 1', signData);
            // await transport.close();

            let sequenceId = Guid.create().toString();
            if (req) {
                if ('sequenceId' in req) {
                    sequenceId = req['sequenceId'] as string;
                }
            }
            respData['txJson']["bodyBytes"] = signData['bodyBytes'];

            console.info('respData', respData);

            let tx = {
                destinationAddress: respData.txOutputs.destinationAddress,
                data: (respData),
                amount: respData.txOutputs.destinationAmount.toString()
            }
            console.log('tx', tx);

            const signTransactionResponse: SignedTransaction = {
                txHex: signData['signatures'],
                halfSigned: {
                    payload: JSON.stringify(tx),
                    txBase64: "",
                    txHex: signData['signatures'],
                    sequenceId: sequenceId
                },
                memo: output.data,
                comment: output.comment
            };
            console.info('signTransactionResponse', signTransactionResponse);

            var sendresult = await this.httpService.SendTransaction(this.wallet.id, signTransactionResponse);
            sendresult = (sendresult)
            console.log(sendresult);
            if (sendresult.status !== 200) {
                return [{ "error": sendresult }]
            }
            return "success";
        }
    }
    catch (err) {
        this.segment.track("send-asset-transaction-sign-failed", {user: this.authService.getUser, error: err})
            .then(() => console.log("Send asset transaction sign failed")).catch((err)=>{});
        if (err instanceof HttpErrorResponse) {
            return [{ error: err?.error?.message || err?.message || err?.error?.code }];
        }
        return [{ error: err }];
    }
}
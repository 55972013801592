export const ExchangesUrls = {
    EXCHANGE_LIST: "/exchange/list",
    EXCHANGE_ACCOUNTS_LIST: "/exchange/accounts",
    FETCH_ASSETS: "/exchange/assets/",
    FETCH_ACCOUNT: "/exchange/account_details/",
    FETCH_ALL_ACCOUNT_KEYS: "/exchange/all_accounts/keys/",
    UPDATE_KEY_STATUS: "/exchange/update_account/status",
    GET_TEAM_MEMBERS: "/exchange/team_members/",
    FETCH_BALANCES: "/exchange/balances/",
    ADD_EXCHANGE: "/exchange/add/",
    WALLET_IDS: "/exchange/walletids/",
    ADD_VIEWERS: "/exchange/add_viewers",
    GET_VIEWERS: "/exchange/account_viewers/",
    UPDATE_KEYS: "/exchange/update_account/keys",
    GET_DEPOSIT_ADDRESS: "/exchange/deposit-address/",
    GET_WALLET_BY_ID: "/exchange/wallet/",
    GET_EXCHANGE_HISTORY: "/exchange/history/",
    GET_REFRESHED_BALANCE: "/exchange/balance_refresh",
    SYNC_BALANCE: "/exchange/syncBalance/",
    GET_EXCHANGE_ASSETS_FOR_RECEIVE: "/exchange/assets/receive/",
}

export const toggleIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAICAYAAADJEc7MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACRSURBVHgBnc/RCcIwEAbg/z/wvSM4gitkAjeRggjSAUTwJW7gJu0IjtARfBc8c3ko9JqW0ntJ+C9fkgM2Fk/nJlJw5PcXYrz3S4fr+rrXnbRp24kIXlBWFlhjBaoIPJnDS3NQZQvqp/SyQyE+bm8OzRlcQnnG0c0OW1ZCE+ixzZ5WeGQlHub/U0NCfUJdCVn9AYaBV93eNb1AAAAAAElFTkSuQmCC";

export const ExchangeWelcomeText = {
    READY: "Ready to Integrate ?",
    START_INTEGRATING: "Start by integrating your exchange account.",
    CONNECT: "Connect to Exchange"
}

export enum Status {
    InActive = 0,
    Active = 1,
}

export const ExchangePromptMessages = {
    ERROR: 'Error',
    DISABLE_KEY: {
        title: 'Disable Key',
        text: 'Are you sure you want to disable this key? This action will deactivate the key immediately.',
        btnText: 'Yes, Disable it',
        icon: 'info_group.svg'
    },
    DISABLE_MAIN_KEY: {
        title: 'Disable Key',
        text: 'Are you sure you want to disable main account key? This action will deactivate all the main and sub account keys immediately.',
        btnText: 'Yes, Disable it',
        icon: 'info_group.svg'
    },
    ENABLE_KEY: {
        title: 'Enable Key',
        text: 'Are you sure you want to enable this key? This action will activate the key immediately.',
        btnText: 'Yes, Enable it',
        icon: 'info_group.svg'
    },
    ENABLE_MAIN_KEY: {
        title: 'Enable Key',
        text: 'Are you sure you want to enable main account key? This action will activate all the main and sub account keys immediately.',
        btnText: 'Yes, Enable it',
        icon: 'info_group.svg'
    },
    SUCCESS_DISABLE_KEY: {
        title: "Success",
        text: "Key disabled successfully!"
    },
    SUCCESS_ENABLE_KEY: {
        title: "Success",
        text: "Key enabled successfully!"
    },
    EXCHANGE_ADD_FAILED: {
        title: "Oops! Something Went Wrong",
        subTitle: "We Couldn't Connect Your Exchange Account.",
        text1: "Please check your details and",
        text2: "If the problem persists, reach out to our",
        tryAgain: "Try Again",
        supportTeam: "Support Team",
        Failed: "Failed to add exchange account",
    },
    EXCHANGE_BALANCE_SYNC_FAILED: {
        title: "Error in Sync Balance",
        subTitle: "Error ocurred in syncing balance from your exchange account , please try again later.",
        confirmButtonText: "Ok",
        Error: "Error Ocurred",
    },
    EXCHANGE_ADD_SUCCESS: {
        title: "Success",
        subTitle: "Your Exchange Account is Now Connected.",
        confirmTxt: "View Exchange Connection"
    },
    EXCHANGE_KEYS_UPDATE_SUCCESS: {
        title: "Success",
        subTitle: "Awesome! You have successfully updated account keys 🎉",
        confirmTxt: "Done"
    },
    EXCHANGE_KEYS_UPDATE_FAILED: {
        title: "Error in updating keys",
        subTitle: "Failed to update exchange keys",
        confirmTxt: "Done"
    },
    MEMBER_ADDITION_SUCCESS: {
        title: "Success",
        subTitle: "Awesome! You have successfully added member as viewer 🎉'",
    }
}

export const AccountToolTips = {
    NO_SUB_ACC_ACCESS: "Sub Account access not available",
    MAIN_ACC_REQ: "A main account is required to connect a sub account",
    ONE_MAIN_ACC: "Only one main account can be connected per exchange"
}

export enum Positions {
    INITIATIORS = 1,
    APPROVERS = 2,
}

export const ExchangeKeyDownMenu = [
    {
        id: 1,
        name: 'Connection',
        icon: '',
        callback: 'updateStatus',
        disabled: false
    },
    {
        id: 2,
        name: 'Update API key',
        icon: 'key.svg',
        callback: 'updateApiKey',
        disabled: false
    },
]

export enum UserType {
    NORMAL = 1,
    ADMIN = 2,
    MANAGER = 3,
}

export enum AccountType {
    SUB = 'sub',
    MAIN = 'main'
}

export enum AccountStatus {
    ACTIVE = 1,
    INACTIVE = 0
}

export enum ExchangeLabels {
    PORTFOLIO = "portfolio",
    TRANS_DIR = "Transfer Direction",
    SEARCH_PLACEHOLDER = "Asset name or symbol",
    SETTINGS_LABEL = "Initiators start the transaction, while Final Approvers provide their approval"
}

export enum TransactionEntity {
    EXCHANGES = "exchanges",
    WALLETS = "wallets",
}

export enum Fieldtype {
    TEXT = "text",
    PASSWORD = "password"
}

export enum ExchangeTransactionType {
    MainToMain = 1,
    MainToSub = 2,
    ExchangeToWallet = 3,
    SubToMain = 4,
    WalletToExchange = 5,
    WalletToWalet = 6,
}

export enum TransactionNameDestination  {
    ExchangeMain = "exchange_main",
    ExchangeSub = "exchange_sub",
    Wallet = "Wallet"
}

export enum PolicyType {
    Address = "Address",
    Wallet = "wallet",
    Exchange = "exchange",
}

export enum ExchangeTxnStatus {
    PENDING = 1,
    COMPLETED = 2,
    WITHDRAW_IN_PROGRESS = 3,
    TRANSFER = 4,
    SUCCESS = 5,
    FAILED = 6,
    CANCELLED = 7,
    REJECTED = 8,
    FIREWALL_PENDING = 9
}

export enum ExchangeTxnStatusText {
    PENDING = "Pending",
    COMPLETED = "Processing",
    WITHDRAW_IN_PROGRESS = "Processing",
    TRANSFER = "Processing",
    SUCCESS = "Success",
    FAILED = "Failed",
    CANCELLED = "Cancelled",
    REJECTED = "Rejected",
    FIREWALL_PENDING = "Firewall Pending"
}

export enum MessageEnum {
    NoWalletsAvailable = "No Wallets available",
    NoExchangesAvailable = "No Exchanges available",
    NoAssetsAvailable = "No Assets available",
  }

export enum exchangeRoute {
    TRANSACTION = "transaction",
    SEND = "send"
}

interface Asset {
    id: number;
    symbol: string;
    walletId: number;
    assetbalance: string;
    assetBalanceUSD: string;
    chain: string;
    coin: string;
    totalBalance: string;
    freeBalance: string;
    quarantinedBalance: string;
    usedBalance: string;
    balanceUpdatedAt: string;
    freeBalanceUSD: number;
    totalBalanceUSD: number;
}

interface Account {
    id: number;
    name: string;
    totalAssetBalance: number;
    totalAssetBalanceUSD: number;
    totalFreeBalance: number;
    totalFreeBalanceUSD: number;
    assets: Asset[];
    accountStatus: number;
    subType: string;
    lastSync: string;
    exchangeName: string;
    type: string;
    exchangeIcon: string;
}

export interface IExchangeData {
    exchange: string;
    exchangeId: number;
    exchangeIcon: string;
    exchangeAppUrl: string;
    exchangeStatus: number;
    meta: string;
    totalAccountsBalancePerExchange: number;
    totalAccountsBalanceUSDPerExchange: number;
    totalAccountsFreeBalancePerExchange: number;
    totalAccountsFreeBalanceUSDPerExchange: number;
    accountId: number;
    name: string;
    accounts: Account[];
}

export enum WalletType {
    Wallet = "wallet",
    Exchange = "exchange",
}

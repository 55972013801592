import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from '../../../shared/services/logger.service';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

import { IAddExchangeAccount } from "../../../shared/entities/Exchange";
import { ExchangesUrls } from 'src/app/shared/constants/exchange.const';

@Injectable({
  providedIn: 'root'
})
export class ExchangeService {
  baseUrl: string = environment.apiUrl;
  urls = ExchangesUrls;
  constructor(
    private httpClient: HttpClient,
    private logger: LoggerService,
  ) { }


  /**
   * Makes a common API call using the specified HTTP method.
   * 
   * @param type - The HTTP method to use (e.g., 'get', 'post', 'put', 'delete').
   * @param url - The URL to send the API request to.
   * @param data - Optional data to send with the API request.
   * @returns An Observable that emits the API response data.
   */
  commonAPiCall(type, url, data?) {
    const attacher = data ?
      this.httpClient[type](`${this.baseUrl}${url}`, data) :
      this.httpClient[type](`${this.baseUrl}${url}`);
    return attacher
      .pipe(
        map((data: string) => {
          return data;
        }),
        catchError((error) => {
          console.error("Error in exchange api call", {url, error});
          return throwError(error.error);
        }))
  }

  getExchangesList() {
    return this.commonAPiCall("get", this.urls.EXCHANGE_LIST);
  }

  getAccountsList() {
    return this.commonAPiCall("get", this.urls.EXCHANGE_ACCOUNTS_LIST);
  }

  async fetchAccountDetailsByExchange(exchangeId: number) {
    const accountData = await this.getAccountsDetailsByExchange(exchangeId).toPromise();
    return accountData;
  }

  getAccountsDetailsByExchange(exchangeId) {
    return this.commonAPiCall("get", `${this.urls.EXCHANGE_ACCOUNTS_LIST}/${exchangeId}`);
  }

  getMainAccounts(subtype: string, exchangeId: number) {
    return this.commonAPiCall("get", `${this.urls.EXCHANGE_ACCOUNTS_LIST}/${exchangeId}/${subtype}`);
  }

  getAssets(accountId: number) {
    return this.commonAPiCall("get", `${this.urls.FETCH_ASSETS}${accountId}`);
  }

  getAccountDetails(accountId: number) {
    return this.commonAPiCall("get", `${this.urls.FETCH_ACCOUNT}${accountId}`);
  }

  getAllAccountKeys(orgId: number) {
    return this.commonAPiCall("get", `${this.urls.FETCH_ALL_ACCOUNT_KEYS}${orgId}`);
  }

  updateAccountKeyStatus(payload: any) {
    return this.commonAPiCall("patch", `${this.urls.UPDATE_KEY_STATUS}`, payload);
  }

  getTeamMembers(orgId: number) {
    return this.commonAPiCall("get", `${this.urls.GET_TEAM_MEMBERS}${orgId}`);
  }

  getBalancesByExchangeId(exchangeId: number) {
    return this.commonAPiCall("get", `${this.urls.FETCH_BALANCES}${exchangeId}`);
  }

  addExchangeAccount(accountDetails: IAddExchangeAccount, orgId: number) {
    return this.commonAPiCall("post", `${this.urls.ADD_EXCHANGE}${orgId}`, accountDetails);
  }

  syncExchangeAccountBalance(accountId: number) {
    return this.commonAPiCall("get", `${this.urls.SYNC_BALANCE}${accountId}`);
  }


  getWalletIds(orgId: number) {
    return this.commonAPiCall("get", `${this.urls.WALLET_IDS}${orgId}`);
  }

  addMemberAsViewer(payload: any) {
    return this.commonAPiCall("post", `${this.urls.ADD_VIEWERS}`, payload);
  }

  getViewers(orgId: number) {
    return this.commonAPiCall("get", `${this.urls.GET_VIEWERS}${orgId}`);
  }

  updateAccountKeys(payload: any) {
    return this.commonAPiCall("patch", `${this.urls.UPDATE_KEYS}`, payload);
  }

  getDepositAddress(asset: string, network:string, id: string) {
    return this.commonAPiCall("get", `${this.urls.GET_DEPOSIT_ADDRESS}${asset}/${network}/${id}`);
  }

  getWalletDetails(id: number) {
    return this.commonAPiCall("get", `${this.urls.GET_WALLET_BY_ID}${id}`);
  }

  getExchangeHistory(accountId: number,pageNumber,pageSize, transferType, fromDate, toDate, searchTerm) {
    let url = `${this.urls.GET_EXCHANGE_HISTORY}${accountId}?page=${pageNumber}&pageSize=${pageSize}`;

    if (transferType) {
      url += `&transferType=${transferType}`;
    }
  
    if (fromDate && toDate) {
      // Encode the date strings to ensure they are properly formatted in the URL
      const encodedFromDate = encodeURIComponent(fromDate);
      const encodedToDate = encodeURIComponent(toDate);
      url += `&fromDate=${encodedFromDate}&toDate=${encodedToDate}`;
    }

    if(searchTerm){
      url += `&searchTerm=${searchTerm}`;
    }
  
    return this.commonAPiCall("get", url);
  }

  getRefreshBalance(){
    return this.commonAPiCall("get", `${this.urls.GET_REFRESHED_BALANCE}`);
  }

  getExchangeAssetsForReceive(accountId: number) {
    return this.commonAPiCall("get", `${this.urls.GET_EXCHANGE_ASSETS_FOR_RECEIVE}${accountId}`); 
  }
}

export interface Members {
    name: string;
    phptoURL: string;
    email: string;
    user_id: number;
}

export interface New_Wallet {
    name: string;
    asset: string;
    m: number;
    group_members: string;
    shield: boolean;
    gas_station: string;
    members_list?: Members[];
}

export interface Wallet {
    wallet: {
        name: string;
        m: number;
        cosigners:string[];
        type: string;
        chain: string;
    }
}

export enum orgUserRequestStatus{
    pending = 0,
    active = 1,
    declined = 2,
    inactive = 3
}

export enum orgType{
    standard = 1,
    management = 2,
    sub = 3,
}

export enum CUSTODY_TYPE {
    NONCUSTODY = 1,
    CUSTODY = 2,
}

export enum UserRoleEnum {
    member = 1,
    admin = 2,
  }

/**
 * Report type enum
 */
export enum ReportType {
    ACCOUNT = 'account',
    COMPLIANCE = 'compliance'
}

/**
 * Download Type
 */
export enum ReportDownloadType {
    CSV = 'csv',
    PDF = 'pdf',
}
export interface ChainKey {
    id: number;
    type: string;
    xpub: string;
    provider: string;
    path: string;
    coin: string;
    userid: number;
    status: number;
    defaultKeyId: string;
    algorithm: string;
    curve: string;
    nodeversion: string | null;
}
export interface OrgMember {
    userid: number;
    isOwner: number;
    type: number;
    id: number;
    displayName: string;
    phptoURL: string;
    email: string;
    status: number;
    userType: number;
    isGasStationUser: boolean;
    keys: { [key: string]: ChainKey };
    providers: string[];
    canBeInitiator: boolean;
}

/**
 * user guiding user type
 */
export enum UserType {
    NON_CUSTODY = 'Non Custody',
    CUSTODIAN = 'Custodian',
    SELF_CUSTODY = 'Self Custody',
    MANAGED_CUSTODY = 'Managed Custody'
}
<div class="page-font" [ngClass]="{'full-bg': !isOrgModal, 'full-modal-bg': isOrgModal, 'lmnl-bg': !isWhitelabel && !isOrgModal}">
    <div class="main-section" *ngIf="!loading">
        <div class="modal-header px-4" *ngIf="isOrgModal">
            <div class="d-flex flex-row align-items-center">
                <span><i class="icon-org mr-2"></i></span>
                <span class="modal-title font-size-16 font-text-dark">Switch Organisation </span>
            </div>
            <button type="button" aria-label="Close" class="close" (click)="close()">
                <i class="icon-close"></i></button>
        </div>

        <div class="org-card" [ngClass]="{'org-card-bg': !isOrgModal, 'pt-3 org-card-modal': isOrgModal  }">
            <div class="header" *ngIf="!isOrgModal">
                <h1 class="body-font mb-0 title-text">Hello {{userInfo?.name}}!</h1>
                <p class="text-dark">Please select organisation to continue</p>
            </div>

            <div class="org-list">
                <ng-container>
                    <ng-container *ngFor="let item of myOrgList">
                        <div class="org-banner mt-3" [ngClass]="{'active-card': item?.orgId === selectedOrg?.orgId && isOrgModal}"
                            (click)="!item?.isInvited && onSelectOrg(item)">
                            <div class="org-logo">
                                <img class="img-circle img-fluid "
                                    [src]="item?.photoUrl || '/assets/images/default-org.png'"
                                    alt="org logo">
                            </div>
                            <div>
                                <div class="body-font mb-1 d-flex">
                                    <div class="text-dot" title="{{item?.orgName}}">{{item?.orgName}}</div>
                                    <span class="current-badge" *ngIf="item?.orgId === currentOrg?.orgId">Current</span>
                                    <span class="new-tag" *ngIf="item?.isInvited"> <span class="dot"></span> New Invite</span>
                                </div>
                                <p class="text-dark mb-1">
                                    <span> {{item?.orgCategory === OrgCategory.SELF_CUSTODY ? 'Self Custody' : 'Managed Custody' }} </span>  
                                    <span *ngIf="!item?.isInvited && item?.createdAt">| Joined on {{item?.createdAt | date }}</span>
                                    <span *ngIf="item?.isInvited && item?.createdAt">| Invited on {{item?.createdAt | date }}</span>
                                </p>
                            </div>
                            <div [ngClass]="{'ml-5': !isOrgModal, 'full-btn': isOrgModal }" *ngIf="item?.isInvited">
                                <button [ngClass]="{'invite-btn-sm': !isOrgModal, 'invite-btn-md': isOrgModal }" class="btn btn-sm invite-btn mr-2" (click)="acceptOrRejectInvite(item, true)">Accept
                                    &
                                    Join</button>
                                <button [ngClass]="{'invite-btn-sm': !isOrgModal, 'invite-btn-md': isOrgModal }" class="btn btn-sm invite-btn text-red"
                                    (click)="rejectInviteConfirm(item);">Reject</button>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>


                <ng-container *ngIf="loading">
                    <div class="mt-3" *ngFor="let item of (myOrgList.length > 0 && myOrgList || [0,0,0,0,0])">
                        <ngx-skeleton-loader count="1" [theme]="{ height: '70px'}"></ngx-skeleton-loader>
                    </div>
                </ng-container>
            </div>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer d-flex align-items-center" *ngIf="isOrgModal">
            <button type="button" class="btn btn-primary w-100"
                [ngClass]="{'disabled': !selectedOrg || selectedOrg.orgId == currentOrg.orgId }"
                [disabled]="!selectedOrg || selectedOrg.orgId == currentOrg.orgId" (click)="onSwitchOrg()">Select
                Organisation</button>
        </div>
    </div>
</div>
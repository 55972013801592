<div class="card card-border" *ngIf="assetsCard === 'exchange' && !validator" [ngClass]="dropdown_card === 'dropdown-card'?'card-dropdown':''">
    <div class="card-body align-items-center" [ngClass]="card_padding">
        <div class="media border-after-xs align-items-center">
            <img class="mr-3 img-fluid img-round-border" [src]="option?.exchangeIcon">
            <div class="d-flex flex-column text-left ml-3">
                <span class="body-font">{{option?.name}}</span>
            </div>
            <div class="media-body text-right">
                <h2 class="card-balance m-0">${{option?.totalFreeBalanceUSD | amountToPretty : 2}}</h2>
            </div>
        </div>
    </div>
</div>

<div class="card card-border" *ngIf="(assetsCard === 'assets_card' || assetsCard === 'exchange')?false:true && !validator" [ngClass]="dropdown_card === 'dropdown-card'?'card-dropdown':''">
    <div class="card-body align-items-center" [ngClass]="card_padding">
        <div class="media border-after-xs align-items-center">
            <img *ngIf="component_from!='walletConnect_personal'" class="mr-3 img-fluid img-round-border" [src]="option?.chain | getIcon">
            <i *ngIf="component_from == 'walletConnect_personal'" class="icon-wallet text-primary icon-round-border-wallet"></i>
            <div class="d-flex flex-column text-left ml-3">
                <span class="body-font">{{option.name}}</span>
                <span class="network text-dark" [ngClass]="option?.chain?.toLowerCase()">
                    {{ option?.chain | getName }}
                </span>
            </div>
            <div class="media-body text-right">
                <h2 class="card-balance m-0">${{sumofwallets(option.id) | amountToPretty : 2}}</h2>
            </div>
        </div>
    </div>
</div>

<div class="card card-border" *ngIf="assetsCard === 'assets_card'?true:false && !validator" [ngClass]="dropdown_card === 'dropdown-card'?'card-dropdown':''">
    <div class="card-body" [ngClass]="card_padding">
        <div class="media border-after-xs align-items-center">
            <img  *ngIf="option.coin!='All Assets'" class="align-self-center mr-2 img-fluid img-round-border" [src]="option.coin | getIcon">
            <img  *ngIf="option.coin=='All Assets'" class="align-self-center mr-2 img-fluid img-round-border" [src]="option.imageurl">
            <div class="details align-self-center body-font">
                {{option.coin}}</div>
            <div class="media-body text-right" *ngIf="!isExchangeAsset">
                <h2 class="card-balance mb-0">${{ option.balanceUSD | number : '1.2-2'}}</h2>
                <span class="card-balance-coin" *ngIf="option.coin!='All Assets'">{{ option.balance | number : '1.5-5'}} {{option.coin}} </span>
            </div>
            <div class="media-body text-right" *ngIf="isExchangeAsset">
                <h2 class="card-balance mb-0">${{ option.freeBalanceUSD | number : '1.2-2'}}</h2>
                <span class="card-balance-coin">{{ option.freeBalance | number : '1.5-5'}} {{option.coin}} </span>
            </div>
        </div>
    </div>
</div>

<div class="card card-border" *ngIf="validator" [ngClass]="dropdown_card === 'dropdown-card'?'card-dropdown':''">
    <div class="card-body align-items-center" [ngClass]="card_padding">
        <div class="media border-after-xs align-items-center">
            <img class="img-fluid img-round-border" [src]="option.logo">
            <div class="d-flex flex-column text-left ml-3">
                <span class="body-font">{{option.provider_name}}</span>
                <span class="text-dark">
                    {{ option.address }}
                </span>
            </div>
        </div>
    </div>
</div>
<!-- page-wrapper Start-->
<div [ngClass]="layout.config.settings.layout_type" [class]="layout.config.settings.layout_version">
  <div *ngIf="!isLoading" class="page-wrapper" id="canvas-bookmark" [ngClass]="layout.config.settings.sidebar.type">

    <div class="d-block d-md-block d-lg-block d-xl-block">
      <app-header></app-header>
    </div>
    <div class="page-body-wrapper" [ngClass]="layout.config.settings.sidebar.body_type">

      <mat-drawer-container [hasBackdrop]="isBackdropVisible" class="sidenav-container" (backdropClick)="onBackdropClick($event)"> 
        <mat-drawer #drawer [mode]="'over'" [opened]="isSidenavOpen">
          <div class="sidebar-wrapper close_icon sidebar-mobile" *ngIf="isSidenavOpen">
            <app-sidebar></app-sidebar>
          </div>
        </mat-drawer>

        <mat-drawer-content>
          <!-- Page Body Start-->
          <div class="sidebar-wrapper close_icon sidebar-desktop" *ngIf="!isSidenavOpen">
            <app-sidebar></app-sidebar>
          </div>
          <div class="page-body">
            <ng-container *ngIf="disableFlow" [ngTemplateOutlet]="completeKycNotice"></ng-container>
            <main [@fadeInAnimation]="getRouterOutletState(o)">
              <div class="mobile-note"><span>To ensure the best user experience, kindly access the platform via a
                  desktop or
                  laptop.</span></div>
              <router-outlet #o="outlet"></router-outlet>
            </main>
          </div>
          <!-- footer start-->
          <footer class="footer d-none d-md-none d-lg-block d-xl-block">
            <app-footer></app-footer>
          </footer>
          <!-- footer End-->
          <!-- Page Body End-->
        </mat-drawer-content>
      </mat-drawer-container>

    </div>
  </div>
</div>
<!-- page-wrapper End-->

<ng-template #completeKycNotice>
  <div class="notice-container mb-4">
    <img src="/assets/images/message.svg" />
    <div class="message-section">
      <p *ngIf="kycInReview"><b>Your Liveness Check is being processed!</b></p>
      <p *ngIf="!kycRejected && !kycInReview"><b>Please Setup Your Profile For Liveness Check</b></p>
      <p *ngIf="kycRejected"><b>Verification Unsuccessful!</b></p>

      <p *ngIf="!isSumsubEnabled && !kycRejected">Complete Liveness Check via emailed link to unlock platform features. Thank you
        for ensuring platform security.</p>
      <p *ngIf="isSumsubEnabled && !kycRejected && !kycInReview">To ensure the safety and compliance of our platform, we request you to verify your identity.</p>
      <p *ngIf="kycRejected">Please follow the guidelines carefully. If you believe this is an error, try again or
        contact support</p>
      <p *ngIf="kycInReview">We will notify you once the verification is complete. Thank you for your cooperation.</p>
    </div>
    <button class="btn btn-round btn-primary" (click)="onVerifyHandler()" [disabled]="kycInReview">
      <ng-container *ngIf="!kycRejected && !kycInReview">Verify Now</ng-container>
      <ng-container *ngIf="kycRejected">Retry Verification</ng-container>
      <ng-container *ngIf="kycInReview">Processing</ng-container>
    </button>
  </div>
</ng-template>


<ng-template #verifyModal let-modal>
  <div class="modal-body verify-modal-container">
    <img src="/assets/images/information.svg" />
    <div class="message-section">
      <h5>We have sent you an email</h5>
      <p>Please locate the relevant link in your email to start the Liveness Check process</p>
    </div>
    <button class="btn btn-round btn-primary" (click)="modal.dismiss()"> I Understand</button>
    <!-- <a href="#" class="guideline-link">View KYC Guidelines</a> -->
  </div>
</ng-template>

<app-liveness-kyc *ngIf="showKycModal" headerTitle="Liveness Check" [isBasicKycCheck]="true"
  (refreshKycStatus)="refreshKycStatus()"></app-liveness-kyc>

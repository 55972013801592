import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganizationService } from './organization.service';

@Injectable()
export class ModifyOrgResponseInterceptor implements HttpInterceptor {
  constructor(private orgSvc: OrganizationService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event) => {
        if (event instanceof HttpResponse && request.url.includes('/account/me')) {
          const modifiedResponse = this.modifyResponse(event.body);
          return event.clone({ body: modifiedResponse });
        }
        return event;
      })
    );
  }

  private modifyResponse(response: any): any {
    if (response && response.organizations && Array.isArray(response.organizations)) {
      const currentOrg = this.orgSvc.getCurrentOrg();
      const currentIndex = response.organizations.findIndex((org: any) => org.id === currentOrg?.orgId);
      if (currentIndex !== -1) {
        const currentOrganizaton = response.organizations.splice(currentIndex, 1)[0];
        currentOrganizaton['isCurrentOrg'] = true;
        response.organizations.unshift(currentOrganizaton);
      }
    }
    return response;
  }
  
}


export const SuccessPromptPayload = {
  INVITE_MEMBER: {
    title: "Success",
    text: "Awesome! You have successfully invited members to this organisation 🎉",
    headerIconClass: "icon-add-member",
    header: "Invite Member",
    confirmButtonText: "Done",
  },
  SPEED_UP_TXN: (textMessage: string) => ({
    title: "Success",
    text: textMessage,
    confirmButtonText: "Done",
  }),
  USER_ROLE_CHANGE: {
    title: "Success",
    text: "Awesome! You have successfully changed the user type 🎉",
    confirmButtonText: "Done",
  },
  TEAM_ADDED: {
    title: "Success",
    text: "Awesome! You have successfully added the team 🎉",
    confirmButtonText: "Done",
  },
  LIVENESS_COMPLETED: {
    title: "Success",
    text: "We have received your transaction request. Transaction is still pending and will continue to Custodian Approval.",
    confirmButtonText: "Done",
  },
  ORG_UPDATE: {
    title: "Success",
    text: "Organisation Details updated successfully",
    confirmButtonText: "Done",
  },
  RESET_PASSWORD: {
    title: "Success",
    text: "We've just sent you an email to reset your password.",
    showConfirmButton: true,
    confirmButtonText: "Done"

  },
  ACCEPT_ORG_INVITE: {
    title: "Success",
    text: "Organisation request accepted successfully",
    confirmButtonText: "Done",
  },
  REJECT_ORG_INVITE: {
    title: "Success",
    text: "Organisation request rejected successfully",
    confirmButtonText: "Done",
  },
  TEAM_UPDATED: {
    title: "Success",
    text: "Changes has been saved successfully!",
    showConfirmButton: true,
    confirmButtonText: "Done"
  }
};

export const ErrorPromptPayload = {
  SAMPLE: {
    title: "Failed to archive wallet",
    text: "Some error ocurred while archiving wallet",
  },
  JWT_EXPIRED: {
    title: "Session Expired",
    text: "Your session has expired. Please log in again to continue",
  },
  SPEED_UP_TXN_FAILED: (textMessage: string) => ({
    title: "Failed to create speed up transaction",
    text: textMessage,
  }),
  TEAM_ADD_FAILED: {
    title: "Oops...",
    text: "Some error ocurred , please try again !",
    confirmButtonText: "Try again"
  },
  RESET_PASSWORD_FAIL: {
    icon: "error",
    title: "Oops...",
    text: "Something went wrong! Kindly contact support if the issue persists!",
  },
  RESET_PASSWORD_AUTH0_ERROR: {
    icon: "error",
    title: "Oops...",
    text:
      "You are using Google authentication for login. To know how to change or reset your password, " +
      '<a href="https://support.google.com/mail/answer/41078?hl=en&co=GENIE.Platform%3DDesktop" target="_blank" style="color:blue;">click here</a>.',
  },
  TEAM_UPDATED_FAILED: {
    icon: "error",
    title: "Oops...",
    text: "Failed to update team, please try again!",
  }
};

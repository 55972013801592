
export interface User {
  displayName: string;
  photoURL?: string;
  email?: string
  phoneNumber?: string
  status?: number
  type?: number;
}
export interface IAuthUserType extends User {
  email_verified?: boolean
}
export interface generate_api {
  client_id: string;
  generated_on?: Date;
  client_secret?: string
}

export enum UserModeEnum {
  NORMAL = 1,
  MACHINE = 2,
}


export enum UserTypeEnum {
  NORMAL = 1,
  ADMIN = 2,
}

export enum RiskDisclosure {
  DNS = 1,
  UNDERSTAND = 2,
  UNDERSTAND_AND_DNS = 3,
}

export enum UserStatusEnum {
  INACTIVE = 0,
  ACTIVE = 1,
  API_KEY_DELETED_OR_DISABLED = 2
}

export interface IUserDetails {
  id: number;
  displayName: string;
  photoURL: string;
  lastLoginAt: string;
  email: string;
  phoneNumber: string | null;
  status: number;
  created_at: string;
  organizations: Organization[];
  keys: { [key: string]: Key[] };
  isOnboardingPageAccess: boolean;
  isOwner?: boolean;
  isAdmin?: boolean;
  userType?: number;
}

interface Organization {
  id: number;
  name: string;
  address: string | null;
  number: string | null;
  billingcycle: string | null;
  lastbill: string;
  billingAmount: number | null;
  orgLogo: string;
  orgType: number;
  managementOrgId: number | null;
  custodyType: number;
  isowner: number;
  type: number;
  features: string[];
  travelRule: number;
  trmStatus: number;
}

export enum OrgStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum orgUserRequestStatus {
  PENDING = 0,
  ACTIVE = 1,
  DECLINED = 2,
  INACTIVE = 3,
}

export enum OrgCategory {
  SELF_CUSTODY = 1,
  MANAGED_CUSTODY = 2,
}
interface Key {
  id: number;
  type: string;
  xpub: string;
  provider: string;
  path: string;
  coin: string;
  userid: number;
  status: number;
  defaultKeyId: string | null;
  algorithm: string;
  curve: string;
  nodeversion: string | null;
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from "../../../environments/environment";

interface RequestOptions {
  headers?: HttpHeaders;
  params?: HttpParams;
}

/**
 * Represents the base API response.
 * @template T - The type of the data in the response.
 */
export interface BaseApiResponse<T> {
  success: boolean;
  message?: string;
  data?: T;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  get<T>(url: string, options: RequestOptions = {}): Observable<T> {
    return this.http.get<T>(this.baseUrl + url, options).pipe(catchError(this.handleError));
  }

  post<T>(url: string, body: any, options: RequestOptions = {}): Observable<T> {
    return this.http.post<T>(this.baseUrl + url, body, options).pipe(catchError(this.handleError));
  }

  put<T>(url: string, body: any, options: RequestOptions = {}): Observable<T> {
    return this.http.put<T>(this.baseUrl + url, body, options).pipe(catchError(this.handleError));
  }

  delete<T>(url: string, options: RequestOptions = {}): Observable<T> {
    return this.http.delete<T>(this.baseUrl + url, options).pipe(catchError(this.handleError));
  }


  // Other methods (patch, head, options) can be added similarly

  private handleError(error: HttpErrorResponse): Observable<never> {
    // Handle and log errors
    return throwError(error);
  }
}

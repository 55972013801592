import { Wallet } from "@entities/wallet";
import axios from "axios";
import { environment } from "src/environments/environment";

export function getParentChain(chain: string) {
  chain = chain.toUpperCase();
  if (
    chain == "BTC" ||
    chain == "DOGE" ||
    chain == "LTC" ||
    chain == "BTG" ||
    chain == "BCH"
  )
    return "UTXO";
  if (
    chain == "ETH" ||
    chain == "BSC" ||
    chain == "XDAI" ||
    chain == "POLYGON" ||
    chain == "EWC" ||
    chain == "ARBITRUM" ||
    chain == "AVALANCHE" ||
    chain == "OPTIMISM" ||
    chain == "FANTOM" ||
    chain == "GETH" ||
    chain == "ETHW" ||
    chain == "XINFIN" ||
    chain == "KLAY" ||
    chain == "TELOS" ||
    chain == "BAHAMUT" ||
    chain == "PLAYA3ULL"
  )
    return "EVM";
  if (chain == "XRP") return "XRPDROP";
  if (chain == "TRON") return "TRON";
  if (chain == "DOT") return "DOT";
  if (chain == "SOL") return "SOL";
  if (chain == "XLM") return "XLM";
  if (chain == "CARDANO") return "CARDANO";
  if (Object.keys(environment.cosmos_config).includes(chain)) {
    return environment.cosmos_config[chain].parentchain;
  }
  if (Object.keys(environment.dot_config).includes(chain)) {
    return environment.dot_config[chain].parentchain;
  } else return "MISC";
  // throw chain+" Chain not supported"
}

export async function getXRPAccountInformation(address: string): Promise<any> {
  var headers = {
    "Content-Type": "application/json",
  };
  var dataString =
    '{"method":"account_info","params":[{"account":"' +
    address +
    '","strict":true,"ledger_index":"current","queue":true}]}';

  return await axios.post(
    environment.xrp_config.XRP.PUBLIC_SERVER,
    dataString,
    { headers: headers }
  );
}
export type SIGNERS = {
  Account: string;
  SigningPubKey: string;
  TxnSignature: string | null;
};

/**
 * Enum representing the subtypes of a wallet.
 */
export enum WalletSubType {
  deposit = "deposit",
  hot = "hot",
  holding = "holding",
  sweep_destination = "sweep_destination",
  hot_pipeline = "hot_pipeline",
  mobile_mpc = "mobile_mpc",
  gasStation = "gasStation",
  viewonly = "viewonly",
  cold = "cold",
}

/**
 * Represents the types of wallets.
 */
export enum WalletType {
  multisig_shield = "multisig_shield",
  multisig = "multisig",
  mpc = "MPC",
  gnosis = "Gnosis",
  pipeline = "pipeline",
  Electrum = "Electrum",
  gasStation = "gasStation",
}

export enum WalletCategory {
  Multisig = "Multisig",
  Withdrawal = "Withdrawal",
  Deposit = "Deposit",
  Mobile = "Mobile",
  Default = "-",
}

/**
 * Returns the wallet category based on the wallet type and subtype.
 * @param walletType - The wallet type.
 * @param walletSubType - The wallet subtype (optional).
 * @returns The wallet category.
 */
export function getWalletType(walletType: string, walletSubType?: string) {
  switch (walletType) {
    case WalletType.gnosis:
    case WalletType.multisig_shield:
    case WalletType.multisig:
      return WalletCategory.Multisig;

    case WalletType.mpc.toLowerCase():
      if (
        WalletSubType.hot === walletSubType ||
        WalletSubType.hot_pipeline === walletSubType
      ) {
        return WalletCategory.Withdrawal;
      } else if (WalletSubType.deposit === walletSubType) {
        return WalletCategory.Deposit;
      }
      return WalletCategory.Mobile;
    case WalletType.pipeline:
      if (WalletSubType.deposit === walletSubType) {
        return WalletCategory.Deposit;
      }
      if (WalletSubType.hot === walletSubType) {
        return WalletCategory.Withdrawal;
      }
    default:
      return WalletCategory.Default;
  }
}

/**
 * Checks if a wallet is a version 2 multisig wallet.
 * @param wallet The wallet object to check.
 * @returns Returns true if the wallet is a version 2 multisig wallet, false otherwise.
 */
export function isV2MultisigWallet(wallet: Wallet) {
  if (
    wallet.version === 2 &&
    (wallet.type === "multisig_shield" || wallet.type === "Gnosis")
  ) {
    return true;
  }
  return false;
}

/**
 * Checks if the given wallet category is a hot withdrawal or deposit wallet.
 *
 * @param walletCategory - The wallet category to check.
 * @returns `true` if the wallet category is a hot withdrawal or deposit wallet, `false` otherwise.
 */
export function isHotWithdrawalOrDepositWallet(
  walletCategory: WalletCategory
): boolean {
  if (
    walletCategory === WalletCategory.Withdrawal ||
    walletCategory === WalletCategory.Deposit
  ) {
    return true;
  }
  return false;
}

import { SegmentService } from 'ngx-segment-analytics';
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import TrezorConnect from "trezor-connect";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ComposeTransaction } from "../../helpers/trezor/transactionComposer";
import Swal from "sweetalert2";
import { IClientMeta } from "@walletconnect/legacy-types";
import { ICore, SessionTypes, SignClientTypes } from "@walletconnect/types";
import { Wallet, WalletUserType } from "../../entities/wallet";
import { DataService } from "../../services/data.service";
import { HttpService } from "../../services/http.service";
import { LoggerService } from "../../services/logger.service";
import { WalletserviceService } from "../../services/walletservice.service";
import { WalletConnectStorageService } from "./wallet-connect-storage.service";
import { switchMap, takeUntil } from "rxjs/operators";
import { Subject, Subscription, interval } from "rxjs";
import BN from "bignumber.js";
import { environment } from "src/environments/environment";
import { UAParser } from "ua-parser-js";
import { AuthServiceJWT } from "../../services/auth.service";
import * as configcat from "configcat-js";
import {
  CreatedMessageRequestData,
  MessageRequestStatus,
  MessageRequestStatusAPIData,
} from "../../entities/messageSigningRequest";
import { SendTeamTxService } from "../../services/sendteamtxn.service";
import {
  createWeb3Wallet,
  disconnectSession,
  extendSession,
  getActiveSessions,
  getPeerMetaDataOfSession,
  pair,
  sendResponse,
  web3wallet,
  EIP155_SIGNING_METHODS,
  isEIP_155signingMethod,
  WalletConnectTransaction,
  TransactionStatus,
  allSupportedEIP155Methods,
  isValidNamespaces,
} from "./wallet-connect.utils";

import { formatJsonRpcError, formatJsonRpcResult } from "@json-rpc-tools/utils";
import jsqr from "jsqr";
import { OrgFeatureName } from '@entities/OrganizationFeatures';
import { ErrorPrompt } from '../custom-prompt/custom-prompt.service';
import { PolicyService } from '../../services/policy.service';
const hex2ascii = require("hex2ascii");
const rs = require("text-readability");

declare const walletconnectUtils: any;

@Component({
  selector: "app-wallet-connect",
  templateUrl: "./wallet-connect.component.html",
  styleUrls: ["./wallet-connect.component.scss"],
  providers: [WalletConnectStorageService],
})
export class WalletConnectComponent implements OnInit {
  readonly IWalletUserType = WalletUserType;
  closeResult = "";
  address = null;
  uri = null;
  decimalAmt: Number;
  payload: any;
  payload_method: string;
  payload_id: number;
  payload_data: any = {};
  peerMeta: IClientMeta | undefined = undefined;
  composeTransaction: ComposeTransaction;
  selectedAsset: Wallet;

  wc: any;
  _wallets: any;
  wallets: Wallet[];

  
  private onDestroy$: Subject<void> = new Subject<void>();
  @ViewChild("content3") sendViewDiv: ElementRef;
  @ViewChild("WalletConnectSend") WalletConnectSend: ElementRef;
  @ViewChild("WalletConnectPersonal") WalletConnectPersonal: ElementRef;
  @ViewChild("WalletConnectDetails") WalletConnectDetails: ElementRef;
  @ViewChild("errorModal") errorModal: ElementRef;
  @ViewChild('banDialog') banDialog: ElementRef;

  videoElement: any;
  scanActive: boolean = false;

  step = 0;
  walletconnectstep = 0;
  selectedWallet: Wallet;
  isSigning = false;
  inputWidth = "";
  isConnected = false;
  nativeCoin;
  searchWalletInput = null;
  meta = null;
  browser: string;
  device: string;
  featureMMI = null;
  mmi: boolean;
  verifyLoopInterval;
  mmiFlag = null;
  messageSigningStatusRequests: MessageRequestStatusAPIData;
  approveMessageRequest = false;
  mpcTypeTxn = false;
  errorMsg = "";
  fltr: any;
  typeDataPayloadData = {};
  typeData = 0;
  version = 0;
  typeData2Address = "";
  swapAssetName = "";
  SourceFrom = "Wallet Connect";
  url = "";
  walletConnectExplainTxn = [];
  latestWallet: any;

  currentTopic: string;
  core: ICore;
  isShowingScanner: boolean = false;
  
  pendingTxnsToBroadcast: WalletConnectTransaction[];
  isConnectingToWalletConnect: boolean = false;

  private intervalSubscription: Subscription;

  constructor(
    private segment: SegmentService,
    private modalService: NgbModal,
    private httpService: HttpService,
    private logger: LoggerService,
    private data: DataService,
    private walletService: WalletserviceService,
    private authService: AuthServiceJWT,
    public sendTeamTxService: SendTeamTxService,
    private walletConnectStorageService: WalletConnectStorageService,
    private policyService: PolicyService
  ) {
    this.browser = new UAParser().getBrowser().name;

    this.wallets = walletService.getAllWallets();

    // Subscribe to the observable to receive updates when the value changes
    this.walletConnectStorageService.pendingTxnsToBroadcast$.subscribe(
      (newPendingTxns) => (this.pendingTxnsToBroadcast = newPendingTxns)
    );

    // Start the interval to trigger the API call every 5 seconds
    this.intervalSubscription = interval(5000)
      .pipe(switchMap(async () => this.triggerApiCall()))
      .subscribe();

    this.data.wallets.pipe(takeUntil(this.onDestroy$)).subscribe((wallets) => {
      if (wallets) {
        this.wallets = wallets;
        this._wallets = [
          ...new Map(
            wallets
              .filter(
                (value) => value.parentchain === "EVM" && value.isArchived != 1
                  && value?.type != 'gasStation' && value?.subtype != 'gasStation'
              )
              .map((item) => [item.id, item])
          ).values(),
        ];
      }
    });

    this.data.getUserProfile.subscribe(async (user) => {
      if (!user) return;
      let features = user["organizations"][0]["features"];
      this.featureMMI = features.some(feature => OrgFeatureName.MMI === feature.toLowerCase());
      if (this.featureMMI) this.verifyMMI();
      this.fltr = user;
    });

    this.data.txMessageSignRequest
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async (tx) => {
        this.mpcTypeTxn = true;
        if (tx) {
          try {
            let messageData: any = {};
            this.payload_data = tx;
            this.SourceFrom =
              tx.source === "mmi" ? "MetaMask Institutional" : "Wallet Connect";
            this.typeData = tx.typedData;

            if (tx?.messageData) {
              this.url = JSON.parse(tx?.messageData)?.url;
            }

            if (tx?.typedData == 1 && tx?.messageData) {
              messageData =
                tx.source === "mmi"
                  ? JSON.parse(tx?.messageData)
                  : JSON.parse(JSON.parse(tx?.messageData)?.params[1]);

              // if()
              this.typeData2Address = messageData?.domain?.verifyingContract;
              this.swapAssetName = messageData?.domain?.name;
              this.typeDataPayloadData = messageData?.message;
            }

            this.approveMessageRequest = true;
            this.selectedWallet = tx?.walletinfor;

            this.modalService.open(this.WalletConnectPersonal, {
              windowClass: "modal-custom-background",
              centered: true,
            });
            this.walletconnectstep = 0;
            this.isSigning = false;
          } catch (err) {
            console.error("error in catch ", err);
          }
        } else {
          this.payload_data = null;
        }
      });

    this.walletService.messageSignStatus.subscribe((getStatus) => {
      if (MessageRequestStatus.Completed == getStatus.status) {
        let updatedStatusData = getStatus;
        updatedStatusData.messageData = getStatus.message_data;
        updatedStatusData.typedData = getStatus.typed_data;

        this.messageSigningStatusRequests = updatedStatusData;
        this.messageSigningReturnTxn();
      } else if (MessageRequestStatus.Cancelled == getStatus.status) {
        localStorage.removeItem("walletConnectMpcTxn");
      }
    });
  }

  ngOnInit(): void {
    // Walletconnect initialization
    this.checkConnection();

    let configCatClient = configcat.createClient(environment.configcat);
    configCatClient.getValue("mmi", false, (value) => {
      this.mmiFlag = value;
    });
  }

  checkConnection = async () => {
    await createWeb3Wallet();
    this.subscribeToEvents();
    await this.checkForWalletsAndCreateSession();
  };

  async checkForWalletsAndCreateSession() {
    this.selectedWallet = JSON.parse(
      localStorage.getItem("walletconnect-wallet")
    );
    let sessions = getActiveSessions();

    if (sessions.length > 0) {
      await extendSession(sessions[0]);
      this.peerMeta = getPeerMetaDataOfSession(sessions[0]);
      this.isConnected = true;
      this.step = 1;
    }
  }

  init = async () => {
    try {
      this.isConnectingToWalletConnect = true;
      await pair({ uri: this.uri });

    } catch (error) {
      await disconnectSession();
      ErrorPrompt.fire({
        icon: "error",
        title: "Unable to connect",
        text: "Please check the entered uri or try again",
        confirmButtonText: "Okay",
      });
      this.isConnectingToWalletConnect = false;
    }
  };

  private subscribeToEvents() {
    web3wallet.on(
      "session_proposal",
      async (
        sessionProposal: SignClientTypes.EventArguments["session_proposal"]
      ) => {
        const { id, params } = sessionProposal;

        const { requiredNamespaces, proposer, optionalNamespaces } = params;

        this.peerMeta = proposer.metadata;

        // Fix for issue raised when connecting to XINFIN
        if(this.selectedWallet.chain.toUpperCase() === "XINFIN"){
          this.address = this.address.replace("xdc", "0x");
        }

        let chainId = environment.evm_config[this.selectedWallet.chain.toUpperCase()].chainId

        let chain = `eip155:${chainId}`;

        let isValidNamespace = isValidNamespaces(requiredNamespaces, optionalNamespaces, chain);

        if (!isValidNamespace) {
          try {
            await web3wallet.rejectSession({
              id,
              reason: walletconnectUtils.getSdkError("USER_REJECTED_METHODS"),
            });

            ErrorPrompt.fire({
              icon: "error",
              title: "Chain mismatch!",
              text: "The requested chain by the dApp doesn't match with that of the current wallet.",
            });
            this.uri = null;
            this.isConnectingToWalletConnect = false;
          } catch (error) {
            this.logger.error("Error in rejecting session");
          }

          return;
        }

        const approvedNamespaces: SessionTypes.Namespaces =
          walletconnectUtils.buildApprovedNamespaces({
            proposal: params,
            supportedNamespaces: {
              eip155: {
                chains: [chain],
                methods: allSupportedEIP155Methods,
                events: ['accountsChanged', 'chainChanged'],
                accounts: [`eip155:${chainId}:${this.address}`],
              },
            },

          });

        try {
          await web3wallet.approveSession({
            id,
            namespaces: approvedNamespaces,
          });

          this.nativeCoin =
            environment.evm_config[this.selectedWallet.chain].nativecoin;

          localStorage.setItem(
            "walletconnect-wallet",
            JSON.stringify(
              this.walletService.getWallet(
                this.selectedWallet.id + "_" + this.nativeCoin.toLowerCase()
              )
            )
          );
          this.selectedWallet = JSON.parse(
            localStorage.getItem("walletconnect-wallet")
          );
          this.step = 1;
          this.isConnected = true;
          this.isConnectingToWalletConnect = false;
        } catch (error) {
          console.log(error);
        }
      }
    );

    web3wallet.on(
      "session_request",
      async (event: SignClientTypes.EventArguments["session_request"]) => {
        const { topic, params, id } = event;
        const { request } = params;

        this.currentTopic = topic;

        this.handleMessageReceived(request, id);
      }
    );

    web3wallet.on("session_delete", async () => {
      await this.deleteSession();
      this.walletConnectStorageService.clearArrayFromLocalStorage();
    });
  }

  private async deleteSession() {
    await disconnectSession();
    this.isConnected = false;
    this.modalService.dismissAll();
    this.reset();
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  hexToText(hex) {
    const FleschReadingEaseScoreLimit = 1;
    const message = hex.replace("0x", "");
    const asciiMessage = hex2ascii(message);
    if (rs.fleschReadingEase(asciiMessage) > FleschReadingEaseScoreLimit) {
      hex = asciiMessage;
    }
    return hex;
  }

  disconnect = async () => {
    await this.deleteSession();
  };

  reset = () => {
    this.peerMeta = undefined;
    localStorage.removeItem("walletconnect-wallet");
    localStorage.removeItem("walletConnectMpcTxn");
  };

  async verifyMMI() {
    try {
      let result = await window.ethereum.request({
        method: "metamaskinstitutional_supported",
      });

      this.mmi = true;
    } catch (e) {
      this.mmi = false;
    }
  }

  async connectMMI() {
    window.ethereum.request({
      method: "metamaskinstitutional_authenticate",
      params: {
        token: this.authService.getToken,
        // 'apiUrl' : 'http://localhost:3001',
        apiUrl: environment.mmi.apiUrl,
        feature: "custodian",
        service: "JSONRPC",
        environment: environment.mmi.environment,
        labels: [
          {
            key: "service",
            value: "Custodian",
          },
          {
            key: "token",
            value: "Token",
          },
        ],
      },
    });
  }

  open(content) {
    this.isSigning = false;
    this.selectedWallet = null;
    this.uri = null;
    this.step = 0;
    this.searchWalletInput = null;

    let modalRef: NgbModalRef = this.modalService.open(content, {
      windowClass: "modal-custom-background",
      centered: true,
    });

    modalRef.shown.subscribe(() => {
      this.videoElement = document.querySelector("video") as HTMLVideoElement;
    });

    modalRef.dismissed.subscribe(() => {
      this.stopScan();
    });
  }

  WalletConnectDetailsPopup(content) {
    this.modalService.open(content, { centered: true });
  }

  async pairconnect() {
    TrezorConnect.renderWebUSBButton();
  }

  selectWallet(wallet) {
    this.address = wallet.address;
    this.selectedWallet = wallet;
  }

  async link_Wallet() {
    await this.init();
  }

  async messageSigningReturnTxn() {
    if (
      this.messageSigningStatusRequests &&
      MessageRequestStatus.Completed == this.messageSigningStatusRequests.status
    ) {
      let { topic, id } = JSON.parse(
        this.messageSigningStatusRequests.messageData
      );

      try {
        await sendResponse(
          topic,
          formatJsonRpcResult(id, this.messageSigningStatusRequests.signature)
        );

        localStorage.removeItem("walletConnectMpcTxn");
      } catch (err) {
        console.error(
          "Error in parse or sending approval request to walletconnect"
        );
      }
    }
  }

  async approveSignTx() {
    this.isSigning = true;
    this.authService
      .writeAccess("team:message-approve")
      .subscribe(async (accessToken) => {
        let body = {
          signature: "2fa",
          messageId: this.payload_data.id,
        };
        try {
          let response = await this.httpService
            .approveMessageSigningRequest(body, accessToken)
            .toPromise();

          if (response.success) {
            let txRequests = await this.httpService
              .getAllPendingMessageRequest()
              .toPromise();
            this.data.changePendingMessageSigningRequest(txRequests.data);
            this.walletconnectstep = 1;

          } else {
            this.errorMsg = response.error.message;
            this.modalService.dismissAll();
            this.modalService.open(this.errorModal, {
              windowClass: "modal-custom-background",
              centered: true,
            });
          }
        } catch (err) {
          this.errorMsg = err;
          this.modalService.dismissAll();
          this.modalService.open(this.errorModal, {
            windowClass: "modal-custom-background",
            centered: true,
          });
        }
      });
  }

  private async handleMessageReceived(payload, id?) {
    this.modalService.dismissAll();
    this.latestWallet = this.data.wallets.value;

    this.payload_method = payload.method;
    this.payload_data = payload;
    this.payload_id = id ? id : payload.id;
    this.meta = null;

    const policies = await this.policyService.getWalletPolicySync(this.selectedWallet.id, true);
    
    const destinationAddress = payload?.params?.[0]?.to?.toLowerCase();
    
    const isEthSendTransactionMethod = EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION === payload?.method;

    const isAddressWhitelisted = policies.find(policy => policy.condition.toLowerCase() === destinationAddress?.toLowerCase());
    
    if (isEthSendTransactionMethod) {
      // If address is not whitelisted, prompt user to whitelist the address and reject the transaction
      if (!isAddressWhitelisted) {
        let whiteListData = {
          isNotWhitelisted: true,
          from: "walletConnect",
          data: {
            wallet: this.selectedWallet,
            destinationAddress,
            url: this.peerMeta.url,
          },
        };
        this.walletService.policyNotWhitelistedChangeStatus(whiteListData);

        // Return error back to the dapp as the policy signing is in progress
        await this.rejectTransaction();
      } else if (isAddressWhitelisted) {
        let whiteListData = {
          isNotWhitelisted: false,
          from: "walletConnect",
          data: {
            wallet: null,
            destinationAddress: null,
            url: null,
          },
        };
        this.walletService.policyNotWhitelistedChangeStatus(whiteListData);
        try {
          if (!("value" in payload.params[0])) payload.params[0]["value"] = "0";
          if (payload.params[0]["data"] && payload.params[0]["data"] != "0x") {
            let response = await this.httpService
              .explainTransaction(payload.params[0], this.selectedWallet.chain)
              .toPromise();
            this.logger.info("Explain Transaction:" + response);
            if (
              ("success" in response && response["success"] == false) ||
              "error" in response
            ) {
            } else {
              this.meta = response;
              //explainn txn
              this.walletConnectExplainTxn = this.meta.asset_changes.map(
                (ele) => ele.tx_detail_description
              );
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
   
    if (isAddressWhitelisted) {
      let whiteListData = {
        isNotWhitelisted: false,
        from: "walletConnect",
        data: {
          wallet: null,
          destinationAddress: null,
          url: null,
        },
      };
      this.walletService.policyNotWhitelistedChangeStatus(whiteListData);
      this.selectedWallet = JSON.parse(
        localStorage.getItem("walletconnect-wallet")
      );
      if (this.selectedWallet.version == 2) {
        this.device = await this.getDeviceName();
      } else {
        this.device = this.selectedWallet.walletKeys.filter(
          (item) => item.ismine === true
        )[0].provider;
      }
      let modalRef = this.modalService.open(this.WalletConnectSend, {
        windowClass: "modal-custom-background",
        centered: true,
      });

      modalRef.result.catch(async (reason) => await this.handleRejectionReason(reason));

      this.walletconnectstep = 0;
      this.isSigning = false;
      this.payload = payload.params[0];
      let amtdecimal = new BN(this.payload.value)
        .dividedBy(new BN(10).pow(18))
        .toFixed();
      this.decimalAmt = parseFloat(amtdecimal);
      this.inputWidth = this.decimalAmt.toString().length * 15 + "px";
    } else if (isEIP_155signingMethod(payload.method)) {
      let whiteListData = {
        isNotWhitelisted: false,
        from: "walletConnect",
        data: {
          wallet: null,
          destinationAddress: null,
          url: null,
        },
      };
      this.walletService.policyNotWhitelistedChangeStatus(whiteListData);
      this.selectedWallet = JSON.parse(
        localStorage.getItem("walletconnect-wallet")
      );
      this.walletconnectstep = 0;
      this.isSigning = false;
      this.approveMessageRequest = false;
      this.SourceFrom = "Wallet Connect";
      this.payload = payload.params[0];
      if (
        EIP155_SIGNING_METHODS.PERSONAL_SIGN === payload.method ||
        EIP155_SIGNING_METHODS.ETH_SIGN === payload.method
      ) {
        this.typeData = 0;
      } else {
        if (
          EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3 === payload.method ||
          EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA === payload.method
        ) {
          this.version = 3;
        } else if (
          EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4 === payload.method
        ) {
          this.version = 4;
        }

        this.typeData = 1;

        try {
          this.typeData2Address = JSON.parse(
            payload?.params[1]
          )?.domain?.verifyingContract;
          this.swapAssetName = JSON.parse(payload?.params[1])?.domain?.name;
          this.typeDataPayloadData = JSON.parse(payload.params[1])?.message;
        } catch (err) {
          console.error(
            "Error in wallet connect parsing payload.param in typeData 1",
            err
          );
        }
      }
      let modalRef = this.modalService.open(this.WalletConnectPersonal, {
        windowClass: "modal-custom-background",
        centered: true,
      });

      modalRef.result.catch(async (reason) => await this.handleRejectionReason(reason));
    }

    let whiteListData = {
      isNotWhitelisted: false,
      from: "walletConnect",
      data: {
        wallet: null,
        destinationAddress: null,
        url: null,
      },
    };
    this.walletService.policyNotWhitelistedChangeStatus(whiteListData);
  }

  // Non-MPC type transaction signing request
  async signTX() {
    this.isSigning = true;

    let txn;

    if (EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION === this.payload_method) {
      txn = {
        from: this.payload.from,
        to: this.payload.to,
        data: this.payload.data,
        value: this.payload.value
          ? parseInt(this.payload.value, 16).toString()
          : "0",
        gas: 469550,
      };
      this.logger.info(JSON.stringify(txn));
      this.nativeCoin =
        environment.evm_config[this.selectedWallet.chain].nativecoin;
      let selectedAsset = this.walletService.getWallet(
        this.selectedWallet.id + "_" + this.nativeCoin.toLowerCase()
      );

      this.composeTransaction = new ComposeTransaction(
        selectedAsset,
        this.data,
        this.httpService,
        this.logger,
        this.segment,
        this.authService,
        this.walletService
      );

      let signResponse = await this.composeTransaction.walletconnectsignTx(
        txn,
        null,
        this.payload_method,
        "normal",
        this.payload_data
      );

      this.logger.info("Sign wallet connect " + signResponse);
      this.isSigning = false;
      if (signResponse[0].error) {
        ErrorPrompt.fire({
          icon: "error",
          title: "Failed to sign transaction!",
          text: this.walletService.setCustomErrorMsg(signResponse[0].error),
          showConfirmButton: false,
        });
      } else {
        this.walletconnectstep = 1;
        this.modalService.dismissAll();
        Swal.fire({
          title: "Success!",
          text: "Awesome! You have successfully initiated the transaction 🎉",
          icon: "success",
          confirmButtonText: "Done",
        });

        // Persist transaction to local storage
        this.addWalletConnectTransaction({
          walletConnect: { id: this.payload_id, topic: this.currentTopic },
          txRequestId: signResponse[2].data?.id,
          walletId: signResponse[2].data?.walletid,
        });
      }
    } else if (isEIP_155signingMethod(this.payload_method)) {
      this.mpcTypeTxn = true;
      this.approveMessageRequest = false;
      this.authService
        .writeAccess("wallet:message-create")
        .subscribe(async (accessToken) => {
          let body = {};
          this.payload_data["url"] = this.peerMeta.url;
          if (this.typeData === 0) {
            let data = {
              ...this.payload_data,
              topic: this.currentTopic,
              id: this.payload_id,
            };
            body = {
              message: this.payload_data.params[0],
              data: JSON.stringify(data),
              typedData: false,
              version: null,
            };
          } else if (this.typeData === 1) {
            body = {
              message: "",
              data: JSON.stringify({
                ...this.payload_data,
                topic: this.currentTopic,
                id: this.payload_id,
              }),
              typedData: true,
              version: this.version,
            };
          }
          try {
            let response = await this.httpService
              .createMessageSigningRequest(
                this.selectedWallet?.id,
                body,
                accessToken
              )
              .toPromise();

            if (response.success) {
              let txRequests = await this.httpService
                .getAllPendingMessageRequest()
                .toPromise();
              this.data.changePendingMessageSigningRequest(txRequests.data);
              response.data["messageData"] = response.data.message_data;
              response.data["typeData"] = response.data.typed_data;

              let intialTxn: CreatedMessageRequestData = response.data;
              this.walletconnectstep = 1;
              localStorage.removeItem("walletConnectMpcTxn");
              localStorage.setItem(
                "walletConnectMpcTxn",
                JSON.stringify(intialTxn)
              );
            } else {
              this.errorMsg = response?.error?.message;
              this.modalService.dismissAll();
              this.modalService.open(this.errorModal, {
                windowClass: "modal-custom-background",
                centered: true,
              });

              await this.rejectTransaction();
            }
          } catch (err) {
            this.errorMsg = err.error.message;
            this.modalService.dismissAll();
            this.modalService.open(this.errorModal, {
              windowClass: "modal-custom-background",
              centered: true,
            });

            await this.rejectTransaction();
          }
        });
    }
  }

  async getDeviceName(): Promise<string> {
    let deviceList = [];
    if (this.fltr.keys["ETH"].length) {
      this.fltr.keys["ETH"].forEach((element) => {
        deviceList.push(element.provider.toLowerCase());
      });
      if (deviceList.includes("trezor")) {
        return "trezor";
      } else if (deviceList.includes("ledger")) {
        return "ledger";
      } else if (
        deviceList.includes("ledger") &&
        deviceList.includes("trezor")
      ) {
        return "trezor";
      }
    } else {
      return;
    }
  }

  async signMPCTX() {
    let toAddress = this.payload?.to;
    let data = {
      data: this.payload.data,
      gasLimit: this.payload.gas,
      topic: this.currentTopic,
      id: this.payload_id,
      isInternal: true
    };
    try {
      const response = await this.sendTeamTxService.mpcTransactionSign(
        this.selectedWallet,
        toAddress,
        this.decimalAmt,
        null,
        this.selectedWallet,
        "",
        null,
        JSON.stringify(data)
      );

      this.isSigning = false;
      this.modalService.dismissAll();
      if (response.success === true) {
        Swal.fire({
          title: "Success!",
          text: "Awesome! You have successfully initiated the transaction 🎉",
          icon: "success",
          showConfirmButton: false,
        });
        let txRequests = await this.httpService
          .getTeamsPendingTxReq()
          .toPromise();

        this.data.changeMpcPendingTxRequest(txRequests.data);

        let txRequest = txRequests.data.find(
          (txn) => response.data.id === txn.id
        );
        // persist the transaction
        this.addWalletConnectTransaction({
          walletConnect: {
            id: this.payload_id,
            topic: this.currentTopic,
          },
          txRequestId: txRequest.txn_req_id,
          walletId: txRequest.walletId,
        });
      } if(response.error.message === 'This address is blocklisted.'){
          this.modalService.open(this.banDialog, { centered: true });
        }
        else {
          ErrorPrompt.fire({
            icon: "error",
            title: "Failed to sign transaction!",
            text: this.walletService.setCustomErrorMsg(response.error.message),
            showConfirmButton: false,
          });
        
        await this.rejectTransaction();
      }
    } catch (err) {
      this.modalService.dismissAll();
      await this.rejectTransaction();
      if (!err?.error?.success) {
        ErrorPrompt.fire({
          icon: "error",
          title: "Failed to sign transaction!",
          text: this.walletService.setCustomErrorMsg(err?.error?.message),
          showConfirmButton: false,
        });
      } else {
        ErrorPrompt.fire({
          icon: "error",
          title: "Failed to sign transaction!",
          text: this.walletService.setCustomErrorMsg(err.message),
          showConfirmButton: false,
        });
      }
    }
  }

  // QR code related functionality

  async showScanner() {
    this.isShowingScanner = true;
    await this.startScan();
  }

  async startScan() {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode: "environment" },
    });

    this.videoElement.srcObject = stream;

    // Required for Safari
    this.videoElement.setAttribute("playsinline", true);

    this.videoElement?.play();

    requestAnimationFrame(this._scan.bind(this));
  }

  private async _scan() {
    if (this.videoElement.readyState === this.videoElement.HAVE_ENOUGH_DATA) {
      this.scanActive = true;

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d")!;
      const videoWidth = this.videoElement.videoWidth;
      const videoHeight = this.videoElement.videoHeight;
      canvas.width = videoWidth;
      canvas.height = videoHeight;

      context.drawImage(this.videoElement, 0, 0, canvas.width, canvas.height);
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

      const code = jsqr(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: "dontInvert",
      });

      if (code && code?.data?.trim()?.length) {
        this.scanActive = false;
        this.uri = code.data;
        this.stopScan();
        this.init();
      } else {
        if (this.scanActive) {
          requestAnimationFrame(this._scan.bind(this));
        }
      }
    } else {
      requestAnimationFrame(this._scan.bind(this));
    }
  }

  stopScan() {
    this.scanActive = false;
    this.isShowingScanner = false;
    const stream = this.videoElement?.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach(function (track: any) {
        track?.stop();
      });

      this.videoElement.srcObject = null;
    }
  }

  clearSelection() {
    this.selectedWallet = null;
    this.stopScan();
    this.uri = null;
  }

  async rejectTransaction() {
    await sendResponse(
      this.currentTopic,
      formatJsonRpcError(
        this.payload_id,
        walletconnectUtils.getSdkError("USER_REJECTED_METHODS").message
      )
    );
  }

  // Method to add an item to the array and save it in local storage
  addWalletConnectTransaction(item: WalletConnectTransaction): void {
    this.walletConnectStorageService.addItemToArray(item);
  }

  // Method to remove an item from the array and update local storage
  removeWalletConnectTransaction(item: WalletConnectTransaction): void {
    this.walletConnectStorageService.removeItemFromArray(item);
  }

  triggerApiCall(): void {
    for (const pendingTxn of this.pendingTxnsToBroadcast) {
      const { walletId, txRequestId, walletConnect } = pendingTxn;
      this.httpService
        .getTransactionRequest(walletId, txRequestId)
        .subscribe(async ({ result }) => {
          switch (result.status) {
            case TransactionStatus.BROADCAST_COMPLETED:
            case TransactionStatus.COMPLETE:
              try {
                await sendResponse(
                  walletConnect.topic,
                  formatJsonRpcResult(walletConnect.id, result.identifier)
                );
              } catch (error) { }
              finally {
                this.removeWalletConnectTransaction(pendingTxn);
              }
              break;
            case TransactionStatus.REJECTED:
              try {
                await sendResponse(
                  walletConnect.topic,
                  formatJsonRpcError(
                    walletConnect.id,
                    walletconnectUtils.getSdkError("USER_REJECTED_METHODS")
                      .message
                  )
                );
              } catch (error) { }
              finally {
                this.removeWalletConnectTransaction(pendingTxn);
              }
              break;

            default:
              break;
          }
        });
    }
  }

  registerRequest() {
    this.segment.track("mmi-request-initiated").catch((err) => { });
  }

  async handleRejectionReason(reason) {
    if (reason === 'Cross click') {
      await this.rejectTransaction();
    }
  }

  ngOnDestroy(): void {
    this.intervalSubscription.unsubscribe();
  }
}

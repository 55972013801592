import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MPCTeamConfig, TeamSigningSubType, TeamSigningType, TeamsV2, ActionDescription, WalletUser } from 'src/app/shared/entities';
import { AddTeamV2Component } from '../../add-team-v2/add-team-v2.component';
import { AccessControlService } from 'src/app/shared/services/access-control.service';
import { MinimumRequiredInitiators } from 'src/app/shared/constants/wallet.const';
import { WalletType } from 'src/app/shared/constants/exchange.const';


export enum MembersType {
  INITIATORS = 1,
  SIGNERS = 2
}

@Component({
  selector: 'app-mobile-teams',
  templateUrl: './mobile-teams.component.html',
  styleUrls: ['./mobile-teams.component.scss']
})
export class MobileTeamsComponent implements OnInit {

  @Input() teamsListData: TeamsV2[];
  @Input() orgProfile: any;
  @Input() teamConfig: MPCTeamConfig;
  @Input() component: string;

  @Input() type: MembersType;

  @Output() onTeamConfigUpdated = new EventEmitter<MPCTeamConfig>();

  searchTerm: string;
  focusTotInit: boolean = false;

  isInitiators: boolean = false;
  isSigners: boolean = false;

  teamName: string;
  teamMembers: any;
  minimumRequiredInitiators  = MinimumRequiredInitiators.MIN_INITIATORS_REQUIRED_NON_CUSTODY;
  isCustodyOrg : boolean = false;
  isNegativeValue: boolean = false;
  signerText: string = WalletUser.SIGNER;
  actionDescription = ActionDescription;
  walletUserType = WalletUser;

  constructor(public modalService: NgbModal, private accessControlService : AccessControlService) { }

  ngOnInit(): void {
    this.isCustodyOrg = this.accessControlService.getIsCustodyOrg();

    if (!this.teamConfig) {
      this.teamConfig = new MPCTeamConfig(this.isCustodyOrg);
    }
    else {
      this.populateValues()
    }

    if (MembersType.INITIATORS === this.type) {
      this.isInitiators = true;
      this.teamsListData = this.teamsListData.filter((team) => TeamSigningType.NON_SIGNING === team.type);

      if(this.isCustodyOrg){
        this.minimumRequiredInitiators = MinimumRequiredInitiators.MIN_INITIATORS_REQUIRED_CUSTODY;
       
        this.teamsListData = this.teamsListData.map(team => ({
          ...team,
          disabled: team.teamMembers.length < this.minimumRequiredInitiators
        }));
        
      }
    }
    else if (MembersType.SIGNERS === this.type) {
      this.isSigners = true;
      this.teamsListData = this.teamsListData.filter((team) => TeamSigningType.SIGNING === team.type && TeamSigningSubType.MPC === team.subType);
      this.teamConfig.minMembersRequired = 1;
    }
    this.signerText = this.component && this.component === WalletType.Exchange ? WalletUser.APPROVER : WalletUser.SIGNER;
  }


  onDropDownBtnClick(selectedTeam: any) {
    // Reset minmembers input
    if (this.teamConfig?.team?.id !== selectedTeam.id) {
      this.teamConfig.minMembersRequired = null;
    }
    // Default to 1 for signers
    if (this.isSigners) this.teamConfig.minMembersRequired = 1;

    this.teamConfig.team = selectedTeam;

    this.teamName = selectedTeam.name;
    this.teamMembers = selectedTeam.teamMembers;

    this.onTeamConfigUpdated.emit(this.teamConfig);
  }

  populateValues() {
    let teamName = this.teamConfig.team?.name
    this.teamName = teamName;
    this.teamMembers = this.teamConfig?.team?.teamMembers;
  }

  onMinMembersRequiredChange() {
    let { minMembersRequired } = this.teamConfig;

    if (minMembersRequired && minMembersRequired >= this.minimumRequiredInitiators && minMembersRequired <= this.teamMembers.length) {
      this.onTeamConfigUpdated.emit(this.teamConfig);
    }
  }

  validateNumberRange(value: number): boolean {
    const min = this.minimumRequiredInitiators;
    const max = this.teamMembers.length;
    this.isNegativeValue = value < 0;
    return Number.isInteger(value) && value >= min && value <= max;
  }

  openAddTeamModal() {
    this.modalService.dismissAll();
    const addTeamModalRef = this.modalService
      .open(AddTeamV2Component, {
        windowClass: "add-team modal-custom-background",
        centered: true,
      })
    addTeamModalRef.componentInstance.orgProfile = this.orgProfile;
  }

  onTeamValueChange(teamData) {
    if(!teamData) {
      this.teamConfig.team = null;
      this.onTeamConfigUpdated.emit(this.teamConfig);
    }
  }

}
